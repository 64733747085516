// @flow
import React from 'react';
import type { Project } from '../../models/projects/Project';
import CardRibbon from './CardRibbon';
import CardDescription from './CardDescrition';
import CardProgress from './CardProgress';
import CardStatus from './CardStatus';
import CardAchievement from './CardAchievement';
import CardPercentage from './CardPercentage';
import CardSpecificInvestor from './CardSpecificInvestor';

type Props = {
  project: Project,
  spefic_investor_flg: boolean,
};

export default function Card(props: Props) {
  const { project, spefic_investor_flg } = props;

  return (
    <div className="col-md-6 mb-30">
      <div className="projects card">
        <CardRibbon project={project} />
        {project.case_studies_size > 0 && (
          <div className="interview">
            <div className="title">
              <i className="fas fa-microphone pr-5" />
              インタビューあり
            </div>
          </div>
        )}
        <div className="card-img">
          <a href={`/projects/${project.id}`} rel="noopener noreferrer">
            <img
              className="card-img-top"
              alt={project.name}
              src={project.image.thumb16by9.url}
            />
          </a>
        </div>
        <div className="card-body">
          <div className="company media">
            <img
              width="64"
              className="media-object"
              src={project.company.image_thumb_url}
            />
            <div className="media-body">
              <span className="text-muted company-name">
                {project.original_company_name
                  ? project.original_company_name
                  : project.company.name}
              </span>
            </div>
          </div>
          <h5 className="card-title">
            <a href={`/projects/${project.id}`} rel="noopener noreferrer">
              {project.name}
            </a>
          </h5>
          <CardDescription project={project} />
          <CardAchievement project={project} />
          <CardProgress project={project} />
          {spefic_investor_flg && (
            <CardSpecificInvestor project={project}></CardSpecificInvestor>
          )}
          <CardPercentage project={project} />
          <CardStatus project={project} />
        </div>
      </div>
    </div>
  );
}
