// @flow
import React from 'react';
import 'moment/locale/ja';
import moment from 'moment';
import { parseISO } from 'date-fns';
import type { Project } from '../../models/projects/Project';
import { STATUS } from '../../models/projects/Project';
import { TEMP_FUNDINNO_3301_PROJECT } from '../../models/projects/Project';

type Props = {
  project: Project,
};

export default function CardStatus(props: Props) {
  const { project } = props;

  // FUNDINNO-2732
  // moment.jsのfromNow関数の仕様は
  // https://momentjscom.readthedocs.io/en/latest/moment/04-displaying/02-fromnow/
  // 22時間以上を1日後 36時間以上を2日後 60時間以上を3日後と判定するわかりにくいロジックなので
  // でhelper（app/helpers/projects_helper.rb) のproject_remain_time関数と齟齬が出る
  // そのためproject_remain_time関数のjs版を用意する

  function projectRemainTime(finish_at) {
    // finish_atはJSTで "Mon, 21 Nov 2022 23:59:00 +0900"のような形
    // ブラウザの時間はブラウザが利用されている場所に依存するのでzonedTimeToUtcで計算
    const diff_time = (parseISO(finish_at) - new Date()) / 1000;

    if (diff_time > 86400)
      // 60*60*24
      return Math.floor(diff_time / 86400) + '日後';
    else if (diff_time > 3600)
      // 60*60
      return Math.floor(diff_time / 3600) + '時間後';
    else if (diff_time > 0) return Math.floor(diff_time / 60) + '分後';
    else return '終了';
  }

  if (
    project.status !== STATUS.failed &&
    moment().isAfter(moment(project.start_at)) &&
    project.products_size > 0
  ) {
    // TODO 一時的に分岐
    // https://fundinno.com/projects/512 用に追加したが後ほど削除
    if (
      project.status === STATUS.inProgress &&
      project.id !== TEMP_FUNDINNO_3301_PROJECT.id
    ) {
      return (
        <div className="status d-flex">
          <div className="flex-fill h-100 d-flex flex-column justify-content-center align-items-center">
            <div className="value">{projectRemainTime(project.finish_at)}</div>
            <div className="title">残り</div>
          </div>
        </div>
      );
    } else if (project.status === STATUS.inProgress) {
      return (
        <div className="status d-flex">
          <div className="flex-fill h-100 border-right d-flex flex-column justify-content-center align-items-center">
            <div className="value">{project.investors_size}人</div>
            <div className="title">投資家</div>
          </div>
          <div className="flex-fill h-100 d-flex flex-column justify-content-center align-items-center">
            <div className="value">{projectRemainTime(project.finish_at)} </div>

            <div className="title">残り</div>
          </div>
        </div>
      );
    } else if (
      project.status <= STATUS.succeeded &&
      project.waiting_wait_order
    ) {
      return (
        <div className="status d-flex">
          <div className="flex-fill h-100 border-right d-flex flex-column justify-content-center align-items-center">
            <div className="value">キャンセル待ち受付中</div>
          </div>
          <div className="flex-fill h-100 d-flex flex-column justify-content-center align-items-center">
            <div className="value">{project.wait_remain_time}</div>
            <div className="title">残り</div>
          </div>
        </div>
      );
    } else if (
      project.status === STATUS.succeeded ||
      project.status === STATUS.executed
    ) {
      return (
        <div className="status d-flex justify-content-center align-items-center">
          募集が終了しています
        </div>
      );
    }
  } else if (
    project.status !== STATUS.failed &&
    (moment(project.start_at).isAfter(moment()) || project.products_size === 0)
  ) {
    return (
      <div className="status d-flex">
        <div className="flex-fill h-100 border-right d-flex flex-column justify-content-center align-items-center">
          <div className="value">
            {moment(project.start_at).format('YYYY年MM月DD日 HH:mm')}
          </div>
          <div className="title">お申し込み開始</div>
        </div>
      </div>
    );
  } else if (project.status === STATUS.failed) {
    if (project.is_withdraw) {
      return (
        <div className="status d-flex justify-content-center align-items-center">
          募集を取り下げました
        </div>
      );
    } else {
      return (
        <div className="status d-flex justify-content-center align-items-center">
          募集が終了しています
        </div>
      );
    }
  }

  return null;
}
