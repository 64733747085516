// @flow
import JQueryView, { on } from '../../../common/JQueryView';

declare var $: any;

export default class OnlineVotingView extends JQueryView {
  constructor() {
    super('#online_voting_area');
    this.renderGeneralMeetingArea();
    this.renderChoicesFields();
  }

  @on('change input[name="company_ir[use_online_voting]"]:radio')
  onChangeUseOnlineVoting() {
    this.renderGeneralMeetingArea();
  }

  renderGeneralMeetingArea() {
    const useOnlineVoting = $('input[name="company_ir[use_online_voting]"]')
      .filter(':checked')
      .val();

    if (useOnlineVoting === 'true') {
      $('#general_meeting_area').show();
      this.renderAgendaNumber();
    } else {
      $('#general_meeting_area').hide();
    }
  }

  @on('change', '.is_choice')
  onChangeIsChoice() {
    this.renderChoicesFields();
  }

  renderChoicesFields() {
    const isChoices = $('input[id$=is_choice]');
    isChoices.each((_, checkbox) => {
      const choiceField = $(checkbox)
        .parent()
        .parent()
        .next();
      $(checkbox).prop('checked') ? choiceField.show() : choiceField.hide();
    });
  }

  renderAgendaNumber() {
    let agendaNumber = 1;
    $('.general-meeting-agenda-form').each((index, element) => {
      const $this = $(element);
      if (!$this.is(':hidden')) {
        $this
          .find('.agenda-number-input')
          .find('input')
          .attr('value', agendaNumber);
        $this
          .find('.agenda-number-view')
          .html(
            '<span class="font-weight-bold">' +
              '第' +
              agendaNumber +
              '号議案' +
              '</span>',
          );
        agendaNumber = agendaNumber + 1;
      }
    });
  }

  @on('click .add_agenda_fields')
  onClickAddAgendaFields(event: any) {
    const time = new Date().getTime();
    const regexp = new RegExp($(event.target).data('id'), 'g');
    $(event.target).before(
      $(event.target)
        .data('fields')
        .replace(regexp, time),
    );
    event.preventDefault();
    this.renderAgendaNumber();
    this.renderChoicesFields();
  }

  @on('click .remove_agenda_fields')
  onClickRemoveAgendaFields(event: any) {
    $(event.target)
      .prev('input[type=hidden]')
      .val('1');
    $(event.target)
      .closest('fieldset')
      .hide();
    event.preventDefault();
    this.renderAgendaNumber();
    this.renderChoicesFields();
  }
}
