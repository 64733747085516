// @flow
import JQueryView, { on } from '../../../common/JQueryView';

declare var $: any;

export default class FormView extends JQueryView {
  constructor() {
    super('#form_view');
    $(document).ready(() => {
      this.render();
    });
  }

  @on('blur #user_corporate_name')
  onBlurCorporateName() {
    if ($('#user_bank_account_really_name').val() == '') {
      $('#user_bank_account_really_name').val($('#user_corporate_name').val());
    }
  }

  @on('blur #user_corporate_name_kana')
  onBlurCorporateNameKana() {
    if ($('#user_bank_account_name').val() == '') {
      $('#user_bank_account_name').val($('#user_corporate_name_kana').val());
    }
  }

  // 決算日の回数を変更したらその分日付入力欄を増減させます
  @on('change input[name="user[settlement_date_count]"]')
  onChangeSettlementDateCount() {
    this.changeSettlementDateList();
  }

  changeSettlementDateList() {
    const isNumeric = $.isNumeric($('#user_settlement_date_count').val());
    if (!isNumeric) {
      return;
    }

    const data = $('form').serialize();
    const url = '/my/investor/wizard/corporate/settlement_dates';
    $.ajax({ url: url, method: 'POST', data: data });
  }

  @on('change input[name="user[corporate_classification]"]:radio')
  onChangeCorporateClassification() {
    this.render();
  }

  @on('change input[name="user[business]"]:radio')
  onChangeBusiness() {
    this.render();
  }

  @on('change #user_capital_character')
  onChangeCapitalCharacter() {
    this.render();
  }

  @on('change #user_purpose')
  onChangePurpose() {
    this.render();
  }

  @on('change #user_income_resource')
  onChangeIncomeResource() {
    const ir = $('#user_income_resource').val();

    if (ir !== '3') {
      $('#user_income_resource_other').val('');
    }

    this.render();
  }

  @on('change input[name="user[has_experience]"]:radio')
  onChangeHasExperience() {
    this.render();
  }

  render() {
    const corporateClassification = $(
      'input[name="user[corporate_classification]"]',
    )
      .filter(':checked')
      .val();
    $('.user_corporate_classification_details').toggle(
      corporateClassification === 'other',
    );

    const business = $('input[name="user[business]"]')
      .filter(':checked')
      .val();
    $('.user_business_details').toggle(business === '9');

    const cc = $('#user_capital_character').val();
    $('.user_capital_character_note').toggle(cc === '7');

    const purpose = $('#user_purpose').val();
    $('.user_purpose_note').toggle(purpose === '5');

    const ir = $('#user_income_resource').val();
    $('.user_income_resource_other').toggle(ir === '3');

    const hasExperience = $('input[name="user[has_experience]"]')
      .filter(':checked')
      .val();
    $('#experience').toggle(hasExperience === 'true');
  }
}
