// @flow
import React from 'react';
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Form,
  Row,
} from 'reactstrap';
import type { Customer } from '../../../../../models/companies/kyccs/Kycc';
import type { Condition } from '../../../../../models/companies/kyccs/Condition';
import { ConditionInput } from './ConditionInput';
import { Target, hasError } from '../../../../../models/companies/kyccs/Kycc';

type Props = {
  target: number,
  customer: Customer,
  onAddCondition: () => void,
  onChangeCondition: (index: number, condition: Condition) => void,
  onRemoveCondition: (index: number) => void,
  onStartCheck: () => void,
};

export function Conditions({
  target,
  customer,
  onAddCondition,
  onChangeCondition,
  onRemoveCondition,
  onStartCheck,
}: Props) {
  return (
    <Row className="conditions">
      <Col md={{ size: 8, offset: 2 }}>
        <Card className="border-light">
          <CardHeader>
            <CardTitle className="text-center">
              <h1>
                {target === Target.domestic
                  ? '個人を検索する'
                  : '海外の個人を検索する'}
                <br />
                <small className="text-muted">
                  {target === Target.domestic
                    ? '今月の残り検索可能件数'
                    : '今月の残り海外検索可能件数'}
                  {customer.kyccMonthlyLimit - customer.kyccCheckedCount}
                </small>
              </h1>
            </CardTitle>
          </CardHeader>
          <CardBody>
            {hasError(customer, 'base') && (
              <Alert color="danger">{customer.errors['base'][0]}</Alert>
            )}
            <h3 className="mb-3">
              検索内容を入力
              <span className="text-muted">
                （生年月日は任意、姓と名の間にスペース不要）
              </span>
            </h3>
            <Form>
              {customer.conditions.map((condition, i) => (
                <ConditionInput
                  key={i}
                  index={i}
                  condition={condition}
                  onChange={condition => onChangeCondition(i, condition)}
                  onRemove={() => onRemoveCondition(i)}
                />
              ))}
              <Row>
                <Col md={{ size: 6, offset: 3 }}>
                  <Button
                    block
                    color="link"
                    size="lg"
                    className="add-button mb-4"
                    onClick={onAddCondition}
                  >
                    <i className="fa fa-plus-circle mr-2"></i>
                    対象を追加する
                  </Button>
                </Col>
                <Col md={{ size: 6, offset: 3 }}>
                  <Button
                    block
                    color="primary"
                    size="lg"
                    onClick={onStartCheck}
                  >
                    検索開始
                  </Button>
                </Col>
              </Row>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}
