// @flow
import JQueryView from '../common/JQueryView';

declare var $: any;

export default class ShowView extends JQueryView {
  constructor() {
    super('.cms_contents');
    document.addEventListener('turbolinks:before-cache', function() {
      $('.js-slick.slick-initialized').slick('unslick');
    });

    var projectNum = $('.cms_contents').find('.c-projectItem').length;

    var projectInfinite;
    var projectCenterMode;
    var projectSlidesToShow;

    if (projectNum < 5) {
      $('.cms_contents .l-container').addClass('-item-4');
      projectInfinite = false;
      projectCenterMode = false;
      projectSlidesToShow = projectNum;
    } else {
      projectInfinite = true;
      projectCenterMode = false;
      projectSlidesToShow = 3;
    }

    $('.js-slick')
      .not('.slick-initialized')
      .slick({
        infinite: projectInfinite,
        autoplay: false,
        dots: false,
        slidesToShow: projectSlidesToShow,
        swipe: true,
        arrows: true,
        touchMove: false,
        variableWidth: true,
        centerMode: projectCenterMode,
        prevArrow: '<button class="slide-arrow slick-prev"></button>',
        nextArrow: '<button class="slide-arrow slick-next"></button>',
        responsive: [
          {
            breakpoint: 1599,
            settings: {
              slidesToShow: 3,
              swipe: true,
            },
          },
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 2,
              swipe: true,
            },
          },
          {
            breakpoint: 1060,
            settings: {
              slidesToShow: 2,
              infinite: true,
              swipe: true,
            },
          },
          {
            breakpoint: 775,
            settings: {
              slidesToShow: 1,
              centerMode: true,
              infinite: true,
              swipe: true,
            },
          },
        ],
      });
  }
}
