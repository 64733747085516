// @flow
import JQueryView, { on } from '../../../common/JQueryView';

export default class SearchView extends JQueryView {
  constructor() {
    super('#search_view');
  }

  @on('click .open')
  onClickOpen(event: any) {
    $(event.currentTarget)
      .closest('.ir-detail')
      .find('.status')
      .html('<span class="text-darkgray">既読</span>');
  }
}
