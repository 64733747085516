// @flow
import React from 'react';
import moment from 'moment';
import { Button } from 'reactstrap';
import type { Condition } from '../../../../../models/companies/kyccs/Condition';
import { ConditionClient } from '../../../../../models/companies/kyccs/ConditionClient';
import { EditNoteModal } from '../EditNoteModal';

type Props = {
  condition: Condition,
};

type State = {
  condition: Condition,
  isOpenModal: boolean,
};

export class ConditionResults extends React.Component<Props, State> {
  conditionClient = new ConditionClient();

  constructor(props: Props) {
    super(props);

    this.state = {
      condition: props.condition,
      isOpenModal: false,
    };
  }

  render() {
    const { condition, isOpenModal } = this.state;
    const { kyccId, id } = condition;

    return (
      <div>
        <div className="d-flex align-items-center target mb-2">
          <span className="mr-3">
            {condition.name}
            {condition.birthday != null
              ? `（${moment(condition.birthday).format('YYYY年MM月DD日')}）`
              : ''}
          </span>
          {kyccId != null && id != null && (
            <Button
              outline
              color="primary"
              onClick={() => this.setState({ isOpenModal: true })}
            >
              メモ
            </Button>
          )}
        </div>
        {condition.results.length > 0 && (
          <table className="table mb-4">
            <thead>
              <tr>
                <th>掲載氏名</th>
                <th>生年月日</th>
                <th>団体名</th>
                <th>団体補足</th>
                <th>媒体</th>
                <th>掲載日</th>
                <th>反社区分</th>
                <th>備考</th>
              </tr>
            </thead>
            <tbody>
              {condition.results.map((result, j) => (
                <tr key={j}>
                  <td className="name">{result.name}</td>
                  <td>{result.birthday}</td>
                  <td>{result.organizationName}</td>
                  <td>{result.organizationSupple}</td>
                  <td>{result.journalName}</td>
                  <td>{result.reportedAt}</td>
                  <td>{result.antisocialGroup}</td>
                  <td>{result.note}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        {condition.results.length == 0 && <div>該当者はいませんでした</div>}
        {kyccId != null && id != null && (
          <EditNoteModal
            isOpen={isOpenModal}
            kyccId={kyccId}
            conditionId={id}
            note={condition.note || ''}
            onClose={() => this.setState({ isOpenModal: false })}
            onChangeNote={note =>
              this.setState({
                isOpenModal: false,
                condition: { ...condition, note: note },
              })
            }
          />
        )}
      </div>
    );
  }
}
