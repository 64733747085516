// @flow
import JQueryView, { on } from '../../../common/JQueryView';

declare var $: any;

export default class FormView extends JQueryView {
  constructor() {
    super('#form_view');
    this.render();
  }

  @on('change #investor_interview_capital_character')
  onChangeCapitalCharacter() {
    this.render();
  }

  @on('change #investor_interview_purpose')
  onChangePurpose() {
    this.render();
  }

  @on('change #investor_interview_income_resource')
  onChangeIncomeResource() {
    const ir = $('#investor_interview_income_resource').val();

    if (ir !== '3') {
      $('#investor_interview_income_resource_other').val('');
    }

    this.render();
  }

  @on('change input[name="investor_interview[has_experience]"]:radio')
  onChangeHasExperience() {
    this.render();
  }

  render() {
    const cc = $('#investor_interview_capital_character').val();
    $('.investor_interview_capital_character_note').toggle(cc === '7');

    const purpose = $('#investor_interview_purpose').val();
    $('.investor_interview_purpose_note').toggle(purpose === '5');

    const ir = $('#investor_interview_income_resource').val();
    $('.investor_interview_income_resource_other').toggle(ir === '3');

    const hasExperience = $('input[name="investor_interview[has_experience]"]')
      .filter(':checked')
      .val();
    $('#experience').toggle(hasExperience === 'true');
  }
}
