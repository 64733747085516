// @flow
import JQueryView, { on } from '../common/JQueryView';

declare var $: any;

export default class PreDownloadView extends JQueryView {
  shareholder_community_id: number;

  constructor(shareholder_community_id: number) {
    super('#pre_download');
    this.shareholder_community_id = shareholder_community_id;
  }

  @on('click #download')
  onClickDownload() {
    const timer = setInterval(() => {
      if (checkDownload() != null) {
        clearInterval(timer);
        if (checkDownload() === 'complete') {
          window.location.href = '/shareholder_communities';
        } else {
          window.location.href = `/shareholder_communities/${this.shareholder_community_id}/pre_download`;
        }
      }
    }, 100);

    function checkDownload() {
      const cookieValue = document.cookie
        .split('; ')
        .find(row => row.startsWith('trade_info_download'));
      if (cookieValue != null) {
        return cookieValue.split('=')[1];
      } else {
        return null;
      }
    }
  }
}
