// @flow
export default class CampaignView {
  constructor() {
    this.scrollfunc();
  }

  scrollfunc() {
    $(window).scroll(() => {
      $('.fadein').each(() => {
        const elemPos = $(this).offset().top;
        const scroll = $(window).scrollTop();
        const windowHeight = $(window).height();
        if (scroll > elemPos - windowHeight + 150) {
          $(this).addClass('scrollin');
        }
      });
    });
    $(window).scroll(() => {
      $('.fadein_mini').each(() => {
        const elemPos = $(this).offset().top;
        const scroll = $(window).scrollTop();
        const windowHeight = $(window).height();
        if (scroll > elemPos - windowHeight + 150) {
          $(this).addClass('scrollin');
        }
      });
    });
    $(window).scroll(() => {
      $('.fadein_medium').each(() => {
        const elemPos = $(this).offset().top;
        const scroll = $(window).scrollTop();
        const windowHeight = $(window).height();
        if (scroll > elemPos - windowHeight + 150) {
          $(this).addClass('scrollin');
        }
      });
    });
  }
}
