// @flow
import JQueryView, { on } from '../../../common/JQueryView';

export default class ShowView extends JQueryView {
  constructor() {
    super('.simple_form');
    this.render();
  }

  @on('click #select-yes-all')
  onClickSelectYesAll() {
    this.renderYesAll();
  }

  @on('change .select-vote :radio')
  onChangeResultRadio() {
    this.render();
  }

  renderYesAll() {
    const selectYes = '1';
    $('.select-vote :radio').each((index, element) => {
      const $this = $(element);
      if ($this.val() === selectYes) {
        $this.prop('checked', true);
      }
    });
    this.render();
  }

  render() {
    const result = $('input[id$=interim_result_2]');
    result.each((_, elm) => {
      const isChoiceCheck = $(elm)
        .parent()
        .parent()
        .parent()
        .next()
        .next()
        .find('.company_general_meeting_vote_agenda_votes_options_exclusion');
      if ($(elm).prop('checked')) {
        isChoiceCheck.each((_, checkbox) => {
          $(checkbox).hide();
        });
      } else {
        isChoiceCheck.each((_, checkbox) => {
          $(checkbox).show();
        });
      }
    });
  }
}
