// @flow
import React from 'react';
import { Alert } from 'reactstrap';
import type {
  Customer,
  CustomerOversea,
} from '../../../../../models/companies/kyccs/Kycc';
import type { Condition } from '../../../../../models/companies/kyccs/Condition';
import { defaultCondition } from '../../../../../models/companies/kyccs/Condition';
import { KyccClient } from '../../../../../models/companies/kyccs/KyccClient';
import { Conditions } from './Conditions';
import { Checking } from '../Checking';
import { Results } from './Results';
import { validate } from '../../../../../models/companies/kyccs/Kycc';

const Status = {
  start: 0,
  cheking: 1,
  done: 2,
};

type Props = {
  target: number,
  kyccMonthlyLimit: number,
  kyccCheckedCount: number,
};

type State = {
  customer: Customer | CustomerOversea,
  status: number,
};

export default class ShowView extends React.Component<Props, State> {
  kyccClient = new KyccClient();

  constructor(props: Props) {
    super(props);

    this.state = {
      customer: {
        id: null,
        companyId: null,
        conditions: [defaultCondition],
        kyccMonthlyLimit: props.kyccMonthlyLimit,
        kyccCheckedCount: props.kyccCheckedCount,
        errors: {},
      },
      status: Status.start,
    };
  }

  onAddCondition() {
    const { customer } = this.state;
    this.setState({
      customer: {
        ...customer,
        conditions: [...customer.conditions, defaultCondition],
      },
    });
  }

  onChangeCondition(index: number, condition: Condition) {
    const { customer } = this.state;
    customer.conditions.splice(index, 1, condition);
    this.setState({
      customer: { ...customer, conditions: customer.conditions },
    });
  }

  onRemoveCondition(index: number) {
    const { customer } = this.state;
    customer.conditions.splice(index, 1);
    this.setState({
      customer: { ...customer, conditions: customer.conditions },
    });
  }

  onStartCheck() {
    const { target } = this.props;
    const { customer } = this.state;

    if (!validate(customer)) {
      this.setState({
        customer: {
          ...customer,
          conditions: [...customer.conditions],
        },
      });
      return;
    }

    this.setState({ status: Status.cheking }, () => {
      this.kyccClient
        .ckeckCustomer(target, customer)
        .then(response => {
          this.setState({
            customer: { ...customer, ...response.data },
            status: Status.done,
          });
        })
        .catch(error => {
          if (error.code === 'ECONNABORTED') {
            customer.errors['base'] = [
              '接続がタイムアウトしました。検索結果が多すぎる可能性があります。条件を絞ってお試しください。',
            ];
          } else {
            if (error.response.status === 422) {
              customer.errors = error.response.data;
            } else if (error.response.status == 500) {
              customer.errors['base'] = [error.response.data.message];
            }
          }

          this.setState({
            customer: { ...customer },
            status: Status.start,
          });
        });
    });
  }

  render() {
    const { target, kyccMonthlyLimit, kyccCheckedCount } = this.props;
    const { customer, status } = this.state;

    if (kyccCheckedCount >= kyccMonthlyLimit) {
      return <Alert color="danger">今月の検索上限に到達しています</Alert>;
    } else {
      if (status === Status.start) {
        return (
          <Conditions
            target={target}
            customer={customer}
            onAddCondition={() => this.onAddCondition()}
            onChangeCondition={(index, condition) =>
              this.onChangeCondition(index, condition)
            }
            onRemoveCondition={index => this.onRemoveCondition(index)}
            onStartCheck={() => this.onStartCheck()}
          />
        );
      } else if (status === Status.cheking) {
        return <Checking />;
      } else if (status === Status.done) {
        return <Results customer={customer} />;
      } else {
        return null;
      }
    }
  }
}
