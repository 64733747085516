// @flow
import JQueryView, { on } from '../../common/JQueryView';
import {
  KYC_TYPES,
  IDENTIFICATION_TYPES,
} from '../../../models/identifications/Identification';

declare var $: any;

export default class FormView extends JQueryView {
  driverLicense1: any;
  driverLicense2: any;
  passport1: any;
  passport2: any;
  residentRegister: any;
  residentRegisterBack: any;
  myNumber1: any;
  publicReceipt1: any;
  publicReceipt2: any;
  others1: any;
  others1Back: any;
  others2: any;
  others2Back: any;
  kycType: any;
  ekycSubmitted: boolean;

  constructor(
    driverLicense1: string,
    driverLicense2: string,
    passport1: string,
    passport2: string,
    residentRegister: string,
    residentRegisterBack: string,
    myNumber1: string,
    publicReceipt1: string,
    publicReceipt2: string,
    others1: string,
    others1Back: string,
    others2: string,
    others2Back: string,
    kycType: number,
    ekycSubmitted: boolean,
  ) {
    super('.simple_form');

    this.driverLicense1 = driverLicense1;
    this.driverLicense2 = driverLicense2;
    this.passport1 = passport1;
    this.passport2 = passport2;
    this.residentRegister = residentRegister;
    this.residentRegisterBack = residentRegisterBack;
    this.myNumber1 = myNumber1;
    this.publicReceipt1 = publicReceipt1;
    this.publicReceipt2 = publicReceipt2;
    this.others1 = others1;
    this.others1Back = others1Back;
    this.others2 = others2;
    this.others2Back = others2Back;
    this.kycType = kycType;
    this.ekycSubmitted = ekycSubmitted;

    this.render();
  }

  @on('change input[name="identification[identification_type]"]:radio')
  onChengeIdentificationType() {
    this.render();
  }

  @on('change input[name="identification[kyc_type]"]:radio')
  onChengeKycType() {
    this.render();
  }

  @on('change input[type=checkbox]')
  onChangeCheckbox() {
    this.render();
  }

  @on('change input[name="identification[is_same_address]"]')
  onChangeIsAddressEdit() {
    this.render();
  }

  @on('change #identification_driver_license1')
  onChangeDriverLicense1(event: any) {
    if (!event.target.files.length) {
      return;
    }

    const file = $(event.target).prop('files')[0];

    if (file.type.indexOf('image/') === 0) {
      this.driverLicense1 = file.name;
      this.render();

      $('#delete_driver_license1').val('');
    } else {
      $('#identification_driver_license1').val('');
    }
  }

  @on('change #identification_driver_license2')
  onChangeDriverLicense2(event: any) {
    if (!event.target.files.length) {
      return;
    }

    const file = $(event.target).prop('files')[0];

    if (file.type.indexOf('image/') === 0) {
      this.driverLicense2 = file.name;
      this.render();

      $('#delete_driver_license2').val('');
    } else {
      $('#identification_driver_license2').val('');
    }
  }

  @on('change #identification_passport1')
  onChangePassport1(event: any) {
    if (!event.target.files.length) {
      return;
    }

    const file = $(event.target).prop('files')[0];

    if (file.type.indexOf('image/') === 0) {
      this.passport1 = file.name;
      this.render();

      $('#delete_passport1').val('');
    } else {
      $('#identification_passport1').val('');
    }
  }

  @on('change #identification_passport2')
  onChangePassport2(event: any) {
    if (!event.target.files.length) {
      return;
    }

    const file = $(event.target).prop('files')[0];

    if (file.type.indexOf('image/') === 0) {
      this.passport2 = file.name;
      this.render();

      $('#delete_passport2').val('');
    } else {
      $('#identification_passport2').val('');
    }
  }

  @on('change #identification_resident_register')
  onChangeResidentRegister(event: any) {
    if (!event.target.files.length) {
      return;
    }

    const file = $(event.target).prop('files')[0];

    if (file.type.indexOf('image/') === 0) {
      this.residentRegister = file.name;
      this.render();

      $('#delete_resident_register').val('');
    } else {
      $('#identification_resident_register').val('');
    }
  }

  @on('change #identification_resident_register_back')
  onChangeResidentRegisterBack(event: any) {
    if (!event.target.files.length) {
      return;
    }

    const file = $(event.target).prop('files')[0];

    if (file.type.indexOf('image/') === 0) {
      this.residentRegisterBack = file.name;
      this.render();

      $('#delete_resident_register_back').val('');
    } else {
      $('#identification_resident_register_back').val('');
    }
  }

  @on('change #identification_my_number1')
  onChangeMyNumber1(event: any) {
    if (!event.target.files.length) {
      return;
    }

    const file = $(event.target).prop('files')[0];

    if (file.type.indexOf('image/') === 0) {
      this.myNumber1 = file.name;
      this.render();

      $('#delete_my_number1').val('');
    } else {
      $('#identification_my_number1').val('');
    }
  }

  @on('change #identification_public_receipt1')
  onChangePublicReceipt1(event: any) {
    if (!event.target.files.length) {
      return;
    }

    const file = $(event.target).prop('files')[0];

    if (file.type.indexOf('image/') === 0) {
      this.publicReceipt1 = file.name;
      this.render();

      $('#delete_public_receipt1').val('');
    } else {
      $('#identification_public_receipt1').val('');
    }
  }

  @on('change #identification_public_receipt2')
  onChangePublicReceipt2(event: any) {
    if (!event.target.files.length) {
      return;
    }

    const file = $(event.target).prop('files')[0];

    if (file.type.indexOf('image/') === 0) {
      this.publicReceipt2 = file.name;
      this.render();

      $('#delete_public_receipt2').val('');
    } else {
      $('#identification_public_receipt2').val('');
    }
  }

  @on('change #identification_others1')
  onChangeOthers1(event: any) {
    if (!event.target.files.length) {
      return;
    }

    const file = $(event.target).prop('files')[0];

    if (file.type.indexOf('image/') === 0) {
      this.others1 = file.name;
      this.render();

      $('#delete_others1').val('');
    } else {
      $('#identification_others1').val('');
    }
  }

  @on('change #identification_others1_back')
  onChangeOthers1Back(event: any) {
    if (!event.target.files.length) {
      return;
    }

    const file = $(event.target).prop('files')[0];

    if (file.type.indexOf('image/') === 0) {
      this.others1Back = file.name;
      this.render();

      $('#delete_others1_back').val('');
    } else {
      $('#identification_others1_back').val('');
    }
  }

  @on('change #identification_others2')
  onChangeOthers2(event: any) {
    if (!event.target.files.length) {
      return;
    }

    const file = $(event.target).prop('files')[0];

    if (file.type.indexOf('image/') == 0) {
      this.others2 = file.name;
      this.render();

      $('#delete_others2').val('');
    } else {
      $('#identification_others2').val('');
    }
  }

  @on('change #identification_others2_back')
  onChangeOthers2Back(event: any) {
    if (!event.target.files.length) {
      return;
    }

    const file = $(event.target).prop('files')[0];

    if (file.type.indexOf('image/') === 0) {
      this.others2Back = file.name;
      this.render();

      $('#delete_others2_back').val('');
    } else {
      $('#identification_others2_back').val('');
    }
  }

  @on('click #remove_driver_license1')
  onClickRemoveDriverLicense1() {
    $('#identification_driver_license1').val('');
    this.driverLicense1 = '';
    $('#remove_driver_license1').hide();
    $('#delete_driver_license1').val('1');
    this.render();
  }

  @on('click #remove_driver_license2')
  onClickRemoveDriverLicense2() {
    $('#identification_driver_license2').val('');
    this.driverLicense2 = '';
    $('#remove_driver_license2').hide();
    $('#delete_driver_license2').val('1');
    this.render();
  }

  @on('click #remove_passport1')
  onClickRemovePassport1() {
    $('#identification_passport1').val('');
    this.passport1 = '';
    $('#remove_passport1').hide();
    $('#delete_passport1').val('1');
    this.render();
  }

  @on('click #remove_passport2')
  onClickRemovePassport2() {
    $('#identification_passport2').val('');
    this.passport2 = '';
    $('#remove_passport2').hide();
    $('#delete_passport2').val('1');
    this.render();
  }

  @on('click #remove_resident_register')
  onClickRemoveResidentRegister() {
    $('#identification_resident_register').val('');
    this.residentRegister = '';
    $('#remove_resident_register').hide();
    $('#delete_resident_register').val('1');
    this.render();
  }

  @on('click #remove_resident_register_back')
  onClickRemoveResidentRegisterBack() {
    $('#identification_resident_register_back').val('');
    this.residentRegisterBack = '';
    $('#remove_resident_register_back').hide();
    $('#delete_resident_register_back').val('1');
    this.render();
  }

  @on('click #remove_my_number1')
  onClickRemoveMyNumber1() {
    $('#identification_my_number1').val('');
    this.myNumber1 = '';
    $('#remove_my_number1').hide();
    $('#delete_my_number1').val('1');
    this.render();
  }

  @on('click #remove_public_receipt1')
  onClickRemovePublicReceipt1() {
    $('#identification_public_receipt1').val('');
    this.publicReceipt1 = '';
    $('#remove_public_receipt1').hide();
    $('#delete_public_receipt1').val('1');
    this.render();
  }

  @on('click #remove_public_receipt2')
  onClickRemovePublicReceipt2() {
    $('#identification_public_receipt2').val('');
    this.publicReceipt2 = '';
    $('#remove_public_receipt2').hide();
    $('#delete_public_receipt2').val('1');
    this.render();
  }

  @on('click #remove_others1')
  onClickRemoveOthers1() {
    $('#identification_others1').val('');
    this.others1 = '';
    $('#remove_others1').hide();
    $('#delete_others1').val('1');
    this.render();
  }

  @on('click #remove_others1_back')
  onClickRemoveOthers1Back() {
    $('#identification_others1_back').val('');
    this.others1Back = '';
    $('#remove_others1_back').hide();
    $('#delete_others1_back').val('1');
    this.render();
  }

  @on('click #remove_others2')
  onClickRemoveOthers2() {
    $('#identification_others2').val('');
    this.others2 = '';
    $('#remove_others2').hide();
    $('#delete_others2').val('1');
    this.render();
  }

  @on('click #remove_others2_back')
  onClickRemoveOthers2Back() {
    $('#identification_others2_back').val('');
    this.others2Back = '';
    $('#remove_others2_back').hide();
    $('#delete_others2_back').val('1');
    this.render();
  }

  render() {
    let kycType = KYC_TYPES.oneDocument;
    if (this.kycType !== KYC_TYPES.oneDocument) {
      // $FlowFixMe
      const params = new URL(document.location).searchParams;
      kycType = parseInt(params.get('kyc_type'));
    }

    const identificationType = parseInt(
      $('input[name="identification[identification_type]"]')
        .filter(':checked')
        .val(),
    );

    $('#driver_license').hide();
    $('#passport').hide();
    $('#my_number').hide();
    $('#resident_register').hide();
    $('#others').hide();
    $('#input-others1').hide();
    $('#input-others2').hide();

    if (kycType === KYC_TYPES.oneDocument) {
      if (
        $('input[name="identification[is_same_address]"]:checked').val() ===
        'false'
      ) {
        $('#public_receipt').show();
      } else {
        $('#public_receipt').hide();
      }

      switch (identificationType) {
        case IDENTIFICATION_TYPES.driverLicense:
          $('#driver_license').show();
          $('#passport').hide();
          $('#resident_register').hide();
          $('#my_number').hide();
          $('#others').hide();
          break;
        case IDENTIFICATION_TYPES.passport:
          $('#driver_license').hide();
          $('#passport').show();
          $('#resident_register').hide();
          $('#my_number').hide();
          $('#others').hide();
          break;
        case IDENTIFICATION_TYPES.residentRegister:
          $('#driver_license').hide();
          $('#passport').hide();
          $('#resident_register').show();
          $('#my_number').hide();
          $('#others').hide();
          break;
        case IDENTIFICATION_TYPES.myNumber:
          $('#driver_license').hide();
          $('#passport').hide();
          $('#resident_register').hide();
          $('#my_number').show();
          $('#others').hide();
          break;
        case IDENTIFICATION_TYPES.others:
          $('#driver_license').hide();
          $('#passport').hide();
          $('#resident_register').hide();
          $('#my_number').hide();
          $('#others').show();
          break;
        default:
          break;
      }
    } else {
      if ($('#identification_select_driver_license').prop('checked') == true) {
        $('#driver_license').show();
      }
      if ($('#identification_select_passport').prop('checked') == true) {
        $('#passport').show();
      }
      if ($('#identification_select_my_number').prop('checked') == true) {
        $('#my_number').show();
      }
      if (
        $('#identification_select_resident_register').prop('checked') == true
      ) {
        $('#resident_register').show();
      }
      if ($('#identification_select_others').prop('checked') == true) {
        $('#others').show();
        $('#input-others1').show();
      }
      if ($('#identification_select_others_second').prop('checked') == true) {
        $('#others').show();
        $('#input-others2').show();
      }

      if (
        $('input[name="identification[is_same_address]"]:checked').val() ===
        'false'
      ) {
        $('#public_receipt').show();
      } else {
        $('#public_receipt').hide();
      }
    }

    const checked = $('input[type=checkbox]:checked').length;
    let not_checked = $('input[type=checkbox]').not(':checked');

    if (checked >= 2) {
      not_checked.attr('disabled', true);
    } else {
      not_checked.attr('disabled', false);
    }

    if (this.driverLicense1 === '') {
      $('#preview_driver_license1')
        .empty()
        .css('height', 'auto')
        .addClass('file-empty')
        .append('ファイルが選択されていません');
      $('#remove_driver_license1').hide();
    } else {
      $('#preview_driver_license1')
        .empty()
        .css('height', 'auto')
        .removeClass('file-empty')
        .append(this.driverLicense1);
      $('#remove_driver_license1').show();
    }

    if (this.driverLicense2 === '') {
      $('#preview_driver_license2')
        .empty()
        .css('height', 'auto')
        .addClass('file-empty')
        .append('ファイルが選択されていません');
      $('#remove_driver_license2').hide();
    } else {
      $('#preview_driver_license2')
        .empty()
        .css('height', 'auto')
        .removeClass('file-empty')
        .append(this.driverLicense2);
      $('#remove_driver_license2').show();
    }

    if (this.passport1 === '') {
      $('#preview_passport1')
        .empty()
        .css('height', 'auto')
        .addClass('file-empty')
        .append('ファイルが選択されていません');
      $('#remove_passport1').hide();
    } else {
      $('#preview_passport1')
        .empty()
        .css('height', 'auto')
        .removeClass('file-empty')
        .append(this.passport1);
      $('#remove_passport1').show();
    }

    if (this.passport2 === '') {
      $('#preview_passport2')
        .empty()
        .css('height', 'auto')
        .addClass('file-empty')
        .append('ファイルが選択されていません');
      $('#remove_passport2').hide();
    } else {
      $('#preview_passport2')
        .empty()
        .css('height', 'auto')
        .removeClass('file-empty')
        .append(this.passport2);
      $('#remove_passport2').show();
    }

    if (this.residentRegister === '') {
      $('#preview_resident_register')
        .empty()
        .css('height', 'auto')
        .addClass('file-empty')
        .append('ファイルが選択されていません');
      $('#remove_resident_register').hide();
    } else {
      $('#preview_resident_register')
        .empty()
        .css('height', 'auto')
        .removeClass('file-empty')
        .append(this.residentRegister);
      $('#remove_resident_register').show();
    }

    if (this.residentRegisterBack === '') {
      $('#preview_resident_register_back')
        .empty()
        .css('height', 'auto')
        .addClass('file-empty')
        .append('ファイルが選択されていません');
      $('#remove_resident_register_back').hide();
    } else {
      $('#preview_resident_register_back')
        .empty()
        .css('height', 'auto')
        .removeClass('file-empty')
        .append(this.residentRegisterBack);
      $('#remove_resident_register_back').show();
    }

    if (this.myNumber1 === '') {
      $('#preview_my_number1')
        .empty()
        .css('height', 'auto')
        .addClass('file-empty')
        .append('ファイルが選択されていません');
      $('#remove_my_number1').hide();
    } else {
      $('#preview_my_number1')
        .empty()
        .css('height', 'auto')
        .removeClass('file-empty')
        .append(this.myNumber1);
      $('#remove_my_number1').show();
    }

    if (this.publicReceipt1 === '') {
      $('#preview_public_receipt1')
        .empty()
        .css('height', 'auto')
        .addClass('file-empty')
        .append('ファイルが選択されていません');
      $('#remove_public_receipt1').hide();
    } else {
      $('#preview_public_receipt1')
        .empty()
        .css('height', 'auto')
        .removeClass('file-empty')
        .append(this.publicReceipt1);
      $('#remove_public_receipt1').show();
    }

    if (this.publicReceipt2 === '') {
      $('#preview_public_receipt2')
        .empty()
        .css('height', 'auto')
        .addClass('file-empty')
        .append('ファイルが選択されていません');
      $('#remove_public_receipt2').hide();
    } else {
      $('#preview_public_receipt2')
        .empty()
        .css('height', 'auto')
        .removeClass('file-empty')
        .append(this.publicReceipt2);
      $('#remove_public_receipt2').show();
    }

    if (this.others1 === '') {
      $('#preview_others1')
        .empty()
        .css('height', 'auto')
        .addClass('file-empty')
        .append('ファイルが選択されていません');
      $('#remove_others1').hide();
    } else {
      $('#preview_others1')
        .empty()
        .css('height', 'auto')
        .removeClass('file-empty')
        .append(this.others1);
      $('#remove_others1').show();
    }

    if (this.others1Back === '') {
      $('#preview_others1_back')
        .empty()
        .css('height', 'auto')
        .addClass('file-empty')
        .append('ファイルが選択されていません');
      $('#remove_others1_back').hide();
    } else {
      $('#preview_others1_back')
        .empty()
        .css('height', 'auto')
        .removeClass('file-empty')
        .append(this.others1Back);
      $('#remove_others1_back').show();
    }

    if (this.others2 === '') {
      $('#preview_others2')
        .empty()
        .css('height', 'auto')
        .addClass('file-empty')
        .append('ファイルが選択されていません');
      $('#remove_others2').hide();
    } else {
      $('#preview_others2')
        .empty()
        .css('height', 'auto')
        .removeClass('file-empty')
        .append(this.others2);
      $('#remove_others2').show();
    }

    if (this.others2Back === '') {
      $('#preview_others2_back')
        .empty()
        .css('height', 'auto')
        .addClass('file-empty')
        .append('ファイルが選択されていません');
      $('#remove_others2_back').hide();
    } else {
      $('#preview_others2_back')
        .empty()
        .css('height', 'auto')
        .removeClass('file-empty')
        .append(this.others2Back);
      $('#remove_others2_back').show();
    }

    return this;
  }
}
