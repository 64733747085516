// @flow
import React from 'react';
import moment from 'moment';
import type { Project } from '../../models/projects/Project';
import { STATUS } from '../../models/projects/Project';

type Props = {
  project: Project,
};

export default function CardProgress(props: Props) {
  const { project } = props;
  const isOver100 = (project.solicited * 100) / project.solicit > 100;

  if (
    project.status !== STATUS.failed &&
    moment().isAfter(moment(project.start_at)) &&
    project.products_size > 0
  ) {
    if (
      project.status === STATUS.executed ||
      project.status >= STATUS.succeeded ||
      project.is_succeeded_with_limit ||
      isOver100
    ) {
      return (
        <div className="indicator">
          <div className="progress-wrapper d-flex justify-content-between align-items-center">
            <i className="fas fa-circle text-primary mr-1" />
            <div className="progress w-100">
              <div className="progress-bar" style={{ width: '100%' }} />
            </div>
            <i className="fas fa-circle text-primary ml-1" />
          </div>
        </div>
      );
    } else if (project.solicited !== 0) {
      const width = (project.solicited * 100) / project.solicit;

      return (
        <div className="indicator">
          <div className="progress-wrapper d-flex justify-content-between align-items-center">
            <i className="fas fa-circle text-primary mr-1" />
            <div className="progress w-100">
              {width < 100 && (
                <div
                  className="progress-bar"
                  style={{ width: `${width > 100 ? 100 : width}%` }}
                >
                  <i className="fas fa-circle text-primary" />
                </div>
              )}
              {width >= 100 && (
                <div className="progress-bar" style={{ width: '100%' }}>
                  <i className="fas fa-circle text-primary" />
                </div>
              )}
            </div>
            <i className="fas fa-circle ml-1" />
          </div>
        </div>
      );
    } else {
      return (
        <div className="indicator">
          <div className="progress-wrapper d-flex justify-content-between align-items-center">
            <i className="fas fa-circle mr-1" />
            <div className="progress w-100">
              <div className="progress-bar" style={{ width: 0 }} />
            </div>
            <i className="fas fa-circle ml-1" />
          </div>
        </div>
      );
    }
  } else if (
    project.status !== STATUS.failed &&
    (moment(project.start_at).isAfter(moment()) || project.products_size === 0)
  ) {
    return (
      <div className="indicator">
        <div className="progress-wrapper d-flex justify-content-between align-items-center">
          <i className="fas fa-circle mr-1" />
          <div className="progress w-100">
            <div className="progress-bar" style={{ width: 0 }} />
          </div>
          <i className="fas fa-circle ml-1" />
        </div>
      </div>
    );
  } else {
    return <div />;
  }
}
