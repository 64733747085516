// @flow
import moment from 'moment';
import type { Result } from './conditions/Result';

export type Condition = {
  id: ?number,
  kyccId: ?number,
  name: ?string,
  birthday: ?Date,
  note: ?string,
  errors: any,
  results: Array<Result>,
};

export const defaultCondition: Condition = {
  id: null,
  kyccId: null,
  name: null,
  birthday: null,
  note: null,
  errors: {},
  results: [],
};

export function validate(form: Condition): boolean {
  form.errors = {};
  if (form.name == null || form.name === '') {
    form.errors['name'] = ['を入力してください'];
  }

  if (form.birthday != null && !moment(form.birthday).isValid) {
    form.errors['birthday'] = ['正しい日付を入力してください'];
  }

  return Object.keys(form.errors).length === 0;
}

export function hasError(condition: Condition, field: string): boolean {
  return condition.errors[field] != null && condition.errors[field].length > 0;
}
