// @flow
import JQueryView, { on } from '../common/JQueryView';

export default class IndexView extends JQueryView {
  constructor() {
    super('.index');
    this.call_ajax('', '', '');
  }

  @on('click td.link-cell')
  onClickLinkCell(event: any) {
    const id = event.currentTarget.dataset.communityId;
    window.location.href = `/shareholder_communities/${id}`;
  }

  @on('change #select_status')
  onChangeSelectStatus() {
    this.call_ajax($('#select_status').val(), '', '');
  }

  call_ajax(select_status: string, sort_column: string, sort_order: string) {
    $.ajax({
      type: 'GET',
      url: $('#community_list_shareholder_community_path').val(),
      data: {
        select_status: select_status,
        sort_column: sort_column,
        sort_order: sort_order,
      },
    });
  }
}
