// @flow
import JQueryView, { on } from '../../../../common/JQueryView';

declare var $: any;

export default class SelectPatternView extends JQueryView {
  constructor() {
    super('.simple_form');

    this.render();
  }

  @on('change input[type="radio"]')
  onChangeRadioButtons() {
    this.render();
  }

  canSubmit() {
    return 0 < $('input[type="radio"]:checked').length;
  }

  render() {
    if (this.canSubmit()) {
      $('#btn-submit').prop('disabled', false);
    } else {
      $('#btn-submit').prop('disabled', true);
    }
  }
}
