// @flow
import React from 'react';
import moment from 'moment';
import type { Project } from '../../models/projects/Project';
import { STATUS } from '../../models/projects/Project';
import { delimited } from '../../helpers/ApplicationHelper';

type Props = {
  project: Project,
};

export default function CardAchievement(props: Props) {
  const { project } = props;

  if (
    (project.status === STATUS.inProgress &&
      moment().isAfter(moment(project.start_at)) &&
      project.products_size > 0) ||
    project.waiting_wait_order
  ) {
    return (
      <div className="achievement clearfix">
        <div className="float-right">
          <span className="solicit">{delimited(project.solicited)}円</span> /{' '}
          {delimited(project.solicit)}円
        </div>
      </div>
    );
  } else if (
    project.status === STATUS.succeeded ||
    project.status === STATUS.executed
  ) {
    return (
      <div className="achievement clearfix">
        <div className="float-right">
          <span className="solicit">
            {delimited(
              project.deliv_price != null
                ? project.deliv_price
                : project.solicited,
            )}
            円{' '}
          </span>
          / {delimited(project.solicit)}円
        </div>
      </div>
    );
  } else if (
    project.status !== STATUS.failed &&
    (moment(project.start_at).isAfter(moment()) || project.products_size === 0)
  ) {
    return (
      <div className="achievement clearfix">
        <div className="text-center">
          <span className="before-in-progress">案件事前開示中</span>
        </div>
      </div>
    );
  } else {
    return <div className="achievement" />;
  }
}
