// @flow
export const JOB = {
  others: 17,
  homemaker: 22,
  student: 23,
  unemployed: 24,
};

export const BUSINESS = {
  others: 9,
};

export const CARRIER_MAIL_PATTERN = [
  '@docomo.ne.jp',
  '@ezweb.ne.jp',
  '@i.softbank.jp',
  '@softbank.ne.jp',
  '@vodafone.ne.jp',
  '@au.com',
];

export function carrierMail(text: string): boolean {
  let result = false;
  CARRIER_MAIL_PATTERN.forEach(pattern => {
    if (!result) {
      result = text.includes(pattern);
    }
  });

  return result;
}
