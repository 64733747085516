// @flow
import JQueryView from '../../../../common/JQueryView';

export default class ShowView extends JQueryView {
  constructor() {
    super('#agreement_button');
    $(window).scroll(function() {
      if ($('#agreement_button_place').length) {
        const footerTop = $('#agreement_button_place').offset().top;
        const scrollPosition = $(window).height() + $(window).scrollTop();
        const footHeight = $('#agreement_button').innerHeight();

        if (scrollPosition - footerTop >= footHeight) {
          $('#agreement_button').css({
            position: 'static',
            bottom: 'auto',
          });
        } else {
          $('#agreement_button').css({
            position: 'fixed',
            bottom: '0px',
          });
        }
      }
    });
  }
}
