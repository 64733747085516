// @flow
import React from 'react';
import { format, parse } from 'date-fns';
import { Card, CardImg, CardText, CardBody, CardTitle } from 'reactstrap';
import type { Company } from '../../models/companies/Company';
import { numberToCurrency } from '../../utils/commonUtils';
import AmountYenSvg from '../../assets/images/views/companies/amount_yen.svg';
import InvestorSvg from '../../assets/images/views/companies/investor.svg';

type Props = {
  company: Company,
};

export function CompanyCard({ company }: Props) {
  return (
    <Card>
      <div className="ribbon">
        <span className="mr-1">最終調達</span>
        {format(
          parse(company.lastFinanceOn, 'yyyy-MM-dd', new Date()),
          'yyyy年MM月dd日',
        )}
      </div>
      <CardImg top width="100%" src={company.thumbUrl} alt={company.name} />
      <CardBody>
        <CardTitle tag="h5">{company.name}</CardTitle>
        <CardText>
          <span className="mr-3">
            <img src={InvestorSvg} width="13" className="svg-icon" />
            {company.investorsSize}人
          </span>
          <span>
            <img src={AmountYenSvg} width="13" className="svg-icon" />
            {numberToCurrency(company.totalFinance)}
          </span>
        </CardText>
      </CardBody>
    </Card>
  );
}
