// @flow
import JQueryView, { on } from '../../../../common/JQueryView';
import swal from 'sweetalert';

export default class ShowView extends JQueryView {
  isPdfClicked: boolean;

  constructor(isPdfClicked: boolean) {
    super('.simple_form');
    this.isPdfClicked = isPdfClicked;
  }

  @on('click .pdf')
  onClickPdf() {
    this.isPdfClicked = true;
  }

  @on('click input[name="commit"]')
  onSubmit() {
    if (!this.isPdfClicked) {
      swal({
        title: '契約締結前交付書面の内容をご確認ください',
        confirmButtonColor: '#2196F3',
        animation: false,
      });
      return false;
    } else {
      return true;
    }
  }
}
