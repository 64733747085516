// @flow
export const CATEGORY = {
  month: 1,
  half: 2,
  year: 3,
  contact: 4,
  periodReport: 5,
  extraMeeting: 6,
  ordinaryMeeting: 7,
  angelTax: 8,
  resultReport: 11,
};
