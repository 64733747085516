// @flow
import React from 'react';
import moment from 'moment';
import type { Project } from '../../models/projects/Project';
import { STATUS } from '../../models/projects/Project';

type Props = {
  project: Project,
};

export default function CardPercentage(props: Props) {
  const { project } = props;

  if (
    (project.status === STATUS.inProgress &&
      moment().isAfter(moment(project.start_at)) &&
      project.products_size > 0) ||
    project.status === STATUS.succeeded ||
    project.status === STATUS.executed
  ) {
    const price = project.deliv_price ? project.deliv_price : project.solicited;
    const percentage = Math.floor((price * 100) / project.solicit);

    return (
      <div className="percentage">
        <span className="title">目標達成率</span>
        <span className="value">{percentage}%</span>
      </div>
    );
  } else {
    return <div />;
  }
}
