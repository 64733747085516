// @flow
import JQueryView from '../common/JQueryView';
import Chart from 'chart.js';

declare var $: any;

export default class TypeRatio extends JQueryView {
  buyAmount: number;
  sellAmount: number;

  constructor(buyAmount: number, sellAmount: number) {
    super('#type_ratio_chart_view');
    this.buyAmount = buyAmount;
    this.sellAmount = sellAmount;
    this.renderPc();
    this.renderSp();
  }

  checkedNumberExist() {
    if (this.buyAmount > 0 || this.sellAmount > 0) {
      return true;
    } else {
      return false;
    }
  }

  returnCheckedNumber() {
    if (this.checkedNumberExist()) {
      return [this.buyAmount, this.sellAmount];
    } else {
      return [-1, -1];
    }
  }

  renderPc() {
    const context = $('#type_ratio_chart_pc')
      .get(0)
      .getContext('2d');

    new Chart(context, {
      type: 'doughnut',
      data: {
        labels: ['買い注文株数', '売り注文株数'],
        datasets: [
          {
            data: this.returnCheckedNumber(),
            backgroundColor: ['#008895', '#DFE3E8'],
            hoverBackgroundColor: ['#008895', '#DFE3E8'],
          },
        ],
      },
      options: {
        tooltips: {
          enabled: this.checkedNumberExist(),
        },
      },
    });
  }

  renderSp() {
    const context = $('#type_ratio_chart_sp')
      .get(0)
      .getContext('2d');

    new Chart(context, {
      type: 'doughnut',
      data: {
        labels: ['買い注文株数', '売り注文株数'],
        datasets: [
          {
            data: this.returnCheckedNumber(),
            backgroundColor: ['#008895', '#DFE3E8'],
            hoverBackgroundColor: ['#008895', '#DFE3E8'],
          },
        ],
      },
      options: {
        tooltips: {
          enabled: this.checkedNumberExist(),
        },
        maintainAspectRatio: false,
      },
    });
  }
}
