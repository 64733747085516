// @flow
import JQueryView, { on } from '../common/JQueryView';

declare var $: any;

export default class RadioContentView extends JQueryView {
  constructor() {
    super('#radio-contents');
  }

  @on('click .modal-open')
  onModalOpen(event: any) {
    const iframe = document.getElementById(
      event.currentTarget.getAttribute('data-iframe-id'),
    );
    const src = $(iframe).attr('data-src');
    $(iframe).attr('src', src);
  }

  @on('click .modal-close')
  onModalClose(event: any) {
    const iframe = document.getElementById(
      event.currentTarget.getAttribute('data-iframe-id'),
    );
    $(iframe).removeAttr('src');
  }
}
