//@flow
import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

type Props = {
  show: boolean,
  taxReturnImage: string,
  taxWithholdingImage: string,
  onClose: () => void,
};

export default function IncomeModal(props: Props) {
  const { show, taxReturnImage, taxWithholdingImage, onClose } = props;
  return (
    <Modal isOpen={show} toggle={onClose}>
      <ModalHeader toggle={onClose}>総所得金額とは？</ModalHeader>
      <ModalBody>
        <div className="mb-20">
          <div className="mb-10">
            <b>① 給与所得の源泉徴収票</b>
            <br />
            所得が1事業者からの給与所得のみで、確定申告を行わず、年末調整のみである場合。
          </div>
          <img src={taxReturnImage} width="100%" alt="給与所得の源泉徴収票" />
        </div>
        <div className="mb-20">
          <div className="mb-10">
            <b>② 所得税の確定申告書</b>
            <br />
            所得税の確定申告を行なっている場合
          </div>
          <img
            src={taxWithholdingImage}
            width="100%"
            alt="所得税の確定申告書"
          />
        </div>
      </ModalBody>
    </Modal>
  );
}
