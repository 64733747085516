// @flow
import JQueryView, { on } from '../../common/JQueryView';
import { browserName } from '../../../helpers/ApplicationHelper';

declare var $: any;

export default class ShowView extends JQueryView {
  tocItems = [];

  constructor(isMobile: boolean) {
    super('#show');

    if (!isMobile) {
      this.renderToc();
      this.handleToc();
      this.handleAnchor();

      //その他の開示中プロジェクト の表示位置を調整する
      if ($('.header-3')[0]) {
        $('.header-to-projects').css({
          position: browserName() === 'safari' ? '-webkit-sticky' : 'sticky',
          bottom: 120,
        });
      } else {
        $('.header-to-projects').css({
          position: browserName() === 'safari' ? '-webkit-sticky' : 'sticky',
          top: window.innerHeight - 80,
        });
      }

      $('.header-3').css({
        position: browserName() === 'safari' ? '-webkit-sticky' : 'sticky',
        bottom: 0,
      });
    } else {
      this.modalIosScrollable();
    }
    this.loadFacebookSdk();
  }

  @on('click .modal-social__btn.share')
  onClickPopup(event: any) {
    event.preventDefault();
    const intWidth = '500';
    const intHeight = '400';
    const strTitle =
        typeof event.currentTarget.title !== 'undefined'
          ? event.currentTarget.title
          : 'Social Share',
      strParam = `width= ${intWidth} ,height= ${intHeight}`;
    window.open(event.currentTarget.href, strTitle, strParam).focus();
  }

  @on('click .risk_info_link')
  onClickRiskInfo() {
    $('.nav-tabs a[href="#risk_info"]').tab('show');
  }

  handleAnchor() {
    const url = document.location.toString();
    if (url.match('#')) {
      $('.tab-nav a[href="#' + url.split('#')[1] + '"]').tab('show');
    }
  }

  handleToc() {
    $('body').scrollspy({ target: '.toc' });

    $('a[data-toggle="tab"]').on('shown.bs.tab', (event: any) => {
      if (
        this.tocItems.length > 0 &&
        $(event.target).html() === 'プロジェクト詳細'
      ) {
        $('.toc').show();
      } else {
        $('.toc').hide();
      }
    });
  }

  renderToc() {
    const selector = '.mokuji';

    $('#description')
      .find(selector)
      .each((i, item) => {
        const trimed = item.dataset.tocTitle
          ? item.dataset.tocTitle.trim()
          : item.textContent.trim();
        const text = trimed.replace('■ ', '').replace('■', '');

        if (text !== '') {
          const index = item.id || `toc-${i}`;
          const sanitizedClassName = selector
            .replace(/((:+[\w-\d]*)|[^A-z0-9-\s])/g, ' ')
            .replace(/\s{2,}/g, ' ')
            .trim();
          const className = `toc-${sanitizedClassName}`;

          if (item.id !== index) {
            item.id = index;
          }

          this.tocItems.push({ index, text, className });
        }
      });

    if (this.tocItems.length > 0) {
      let html = '<div class="list-group" data-turbolinks="false">';
      html += '<div class="list-group-item list-group-item-heading">目次</div>';

      const triggerOptions = [];

      this.tocItems.forEach((item, j) => {
        const options = {
          el: `.toc-li-${j}`,
          fixed: 'true',
          start: `#${item.index}`,
          position: 'top',
          positionEnd: 'top',
          className: 'toc-visible',
        };
        html += `\n<a href="#${item.index}" class="list-group-item list-group-item-action toc-li-${j} ${item.className}">${item.text}</a>`;
        triggerOptions.push(options);
      });

      html += '</ul>';

      $('.toc').html(html);
    } else {
      $('.toc').hide();
    }
  }

  loadFacebookSdk() {
    (function(d, s, id) {
      if (d.getElementById(id)) {
        return;
      }

      const fjs: any = d.getElementsByTagName(s)[0];
      let js = d.createElement(s);
      js.id = id;
      // $FlowFixMe
      js.src = 'https://connect.facebook.net/ja_JP/sdk.js#xfbml=1&version=v3.1';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'footer', 'facebook-jssdk');
  }

  modalIosScrollable() {
    let previousScrollY = 0;

    $(document)
      .on('show.bs.modal', () => {
        previousScrollY = window.scrollY;
        $('html')
          .addClass('modal-open')
          .css({
            marginTop: -previousScrollY,
            overflow: 'hidden',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            position: 'fixed',
          });
      })
      .on('hidden.bs.modal', () => {
        $('html')
          .removeClass('modal-open')
          .css({
            marginTop: 0,
            overflow: 'visible',
            left: 'auto',
            right: 'auto',
            top: 'auto',
            bottom: 'auto',
            position: 'static',
          });
        window.scrollTo(0, previousScrollY);
      });
  }
}
