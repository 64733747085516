// @flow
import JQueryView, { on } from '../../common/JQueryView';
import { carrierMail } from '../../../models/users/User';

declare var $: any;

export default class FormView extends JQueryView {
  investor: boolean;

  constructor(investor: boolean) {
    super('.simple_form');
    this.investor = investor;
    this.renderCarrierMailAttention();
  }

  @on('change #display_password')
  onChangeDisplayPassword() {
    const displayPassword = $('#display_password')
      .filter(':checked')
      .val();
    $('#user_password').attr({
      type: displayPassword != null ? 'text' : 'password',
    });
  }

  @on('input #user_email')
  onInputUserEmail() {
    this.renderCarrierMailAttention();
  }

  // Regexp from
  //  https://developer.mozilla.org/ja/docs/Web/HTML/Element/input/email#%E5%9F%BA%E6%9C%AC%E7%9A%84%E3%81%AA%E6%A4%9C%E8%A8%BC
  @on('input .js-email-input')
  onInputUserEmailValidate(e: any) {
    const validFormatRegExp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    const $emailInput = $(e.currentTarget);
    const $emailValidate = $('.js-email-validate');
    const $emailInvalidFeedback = $('.js-email-invalid-feedback');
    let errors = [];

    if (!$emailInput.val()) {
      errors.push('メールアドレスを入力してください');
    }

    if ($emailInput.val() && !$emailInput.val().match(validFormatRegExp)) {
      errors.push('メールアドレスは不正な値です');
    }

    if (errors.length > 0) {
      $emailValidate.addClass('is-invalid');
      $emailValidate.removeClass('is-entered');
      $emailInvalidFeedback.html(errors.join('と'));
    } else {
      $emailValidate.removeClass('is-invalid');
      $emailValidate.addClass('is-entered');
    }
  }

  @on('input .js-password-input')
  onInputUserPasswordValidate(e: any) {
    const minimumLength = 8;
    const $passwordValidate = $('.js-password-validate');
    const $passwordInvalidFeedback = $('.js-password-invalid-feedback');
    let errors = [];

    if ($(e.currentTarget).val().length < minimumLength) {
      errors.push(`パスワードを${minimumLength}文字以上で入力してください`);
    }

    if (errors.length > 0) {
      $passwordValidate.addClass('is-invalid');
      $passwordValidate.removeClass('is-entered');
      $passwordInvalidFeedback.html(errors.join('と'));
    } else {
      $passwordValidate.removeClass('is-invalid');
      $passwordValidate.addClass('is-entered');
    }
  }

  @on('click .js-toggle-password-trigger')
  onToggleShowPassword(e: any) {
    $(e.currentTarget).toggleClass('is-checked');

    if ($(e.currentTarget).hasClass('is-checked')) {
      $('#user_password').attr('type', 'text');
    } else {
      $('#user_password').attr('type', 'password');
    }
  }

  renderCarrierMailAttention() {
    if (this.investor) {
      const text = $('#user_email').val();
      if (carrierMail(text)) {
        $('#user_email').addClass('carrier-email');
        $('#carrier_attention').show();
      } else {
        $('#user_email').removeClass('carrier-email');
        $('#carrier_attention').hide();
      }
    }
  }
}
