// @flow
import React from 'react';

type Props = {
  order: ?string,
  keyword: string,
  onChangeKeyword: (e: any) => void,
  onClickOrder: (order: ?string) => void,
  onClickKeywordSearch: () => void,
};

type State = {
  keyword: string,
  order: ?string,
  checked: boolean,
};

export default class MobileSelector extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      keyword: props.keyword,
      order: props.order,
      checked: false,
    };
  }

  onClickSearchToggle() {
    this.setState({ checked: !this.state.checked });
  }

  resetKeyword(fn: () => void) {
    this.setState({ keyword: '', checked: false });
    fn();
  }

  resetFilter(fn: () => void) {
    this.setState({ order: null });
    fn();
  }

  render() {
    const {
      order,
      keyword,
      onChangeKeyword,
      onClickOrder,
      onClickKeywordSearch,
    } = this.props;
    const { checked } = this.state;

    return (
      <React.Fragment>
        <div className="g-filter">
          <div className="list-group list-group-horizontal project-selector__navi">
            <a
              className="navi-item__wrapper"
              onClick={() =>
                this.resetKeyword(() => onClickOrder('in_progress'))
              }
            >
              <div
                className={`list-group-item navi-item ${
                  order === 'in_progress' ? 'active' : ''
                }`}
              >
                募集中の投資先
              </div>
            </a>
            <a
              className="navi-item__wrapper"
              onClick={() => this.resetKeyword(() => onClickOrder(null))}
            >
              <div
                className={`list-group-item navi-item ${
                  order === null ? 'active' : ''
                }`}
              >
                全て
              </div>
            </a>
          </div>
          <div
            className="search-icon__wrapper"
            onClick={() => this.onClickSearchToggle()}
          >
            <span className={`search-icon ${checked ? 'is-checked' : ''}`} />
          </div>
        </div>
        <div className={`search-container ${checked ? 'is-checked' : ''}`}>
          <input
            type="text"
            placeholder="キーワードで検索"
            value={keyword}
            onChange={onChangeKeyword}
          />
          <button
            onClick={() => checked && this.resetFilter(onClickKeywordSearch)}
          >
            検索
          </button>
        </div>
      </React.Fragment>
    );
  }
}
