// @flow
import AxiosClient from '../AxiosClient';
import type { AxiosPromise } from 'axios';
import type { Project } from './Project';

export default class ProjectClient extends AxiosClient {
  fetchAll(
    page: number,
    keyword: ?string,
    order: ?string,
    category: ?number,
  ): AxiosPromise<Array<Project>> {
    return this.buildClient().get('/api/v1/projects/stagings.json', {
      params: {
        page: page,
        keyword: keyword,
        order: order,
        category: category,
      },
    });
  }
}
