// @flow
import JQueryView, { on } from '../../../common/JQueryView';

export default class FormView extends JQueryView {
  unit: number;
  price: number;

  constructor(unit: number, price: number) {
    super('.simple_form');
    this.unit = unit;
    this.price = price;
    this.render();
  }

  @on('keyup #stock_unit_amount')
  onKeyupStockUnitAmount() {
    this.render();
  }

  @on('change #stock_unit_amount')
  onChangeStockUnitAmount() {
    this.render();
  }

  render() {
    const amount = parseInt($('#stock_unit_amount').val()) * this.unit;
    $('#stock_amount').html(amount.toString());
    $('#payment').html((amount * this.price).toString());
    $('#company_stock_transfer_amount').val(amount);
  }
}
