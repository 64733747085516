// @flow
import React from 'react';

type Props = {
  type?: ?string,
};

export default function Loader(props: Props) {
  const { type } = props;

  if (type === 'water') {
    return (
      <div className="loader">
        <div id="circle_1" className="circle" />
        <div id="circle_2" className="circle" />
        <div id="circle_3" className="circle" />
        <div className="clearfix" />
      </div>
    );
  } else {
    return <div className="loading" />;
  }
}
