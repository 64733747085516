// @flow
import React from 'react';
import { SELECT_BRANCH_VIEW } from './BankModalView';
import type { Bank } from '../../models/banks/Bank';
import type { BankBranch } from '../../models/banks/BankBranch';

type Props = {
  bank: Bank,
  branch: BankBranch,
  onTransition: (view: number) => void,
  onOk: () => void,
  onCancel: () => void,
};

export default class BranchConfirmView extends React.Component<Props> {
  render() {
    return (
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header text-center">
            <h5 className="modal-title w-100">確認画面</h5>
            <button
              type="button"
              className="close"
              onClick={() => this.props.onCancel()}
            >
              <span>&times;</span>
            </button>
          </div>
          <div className="modal-body p-40">
            <dl className="mb-30">
              <dt>金融機関名</dt>
              <dd>{this.props.bank.name}</dd>
              <dt>支店名</dt>
              <dd>{this.props.branch.name}</dd>
            </dl>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-lg btn-flat btn-outline-secondary mr-20"
              onClick={() => this.props.onTransition(SELECT_BRANCH_VIEW)}
            >
              戻る
            </button>
            <button
              type="button"
              className="btn btn-lg btn-flat btn-outline-secondary mr-20"
              onClick={() => this.props.onCancel()}
            >
              キャンセル
            </button>
            <button
              type="button"
              className="btn btn-lg btn-flat btn-outline-primary"
              onClick={() => this.props.onOk()}
            >
              完了
            </button>
          </div>
        </div>
      </div>
    );
  }
}
