// @flow
import Chart from 'chart.js';

export default class StatsView {
  constructor() {
    const ctra = document.getElementById('contract-amount');
    new Chart(ctra, {
      // グラフの種類：折れ線グラフを指定
      type: 'bar',
      data: {
        // x軸の各メモリ
        labels: [
          '2017年',
          '2018年',
          '2019年',
          '2020年',
          '2021年',
          '2022年',
          '2023年',
        ],
        datasets: [
          {
            label: '年別累計成約額',
            fill: false,
            data: [4.5, 16.8, 26.8, 41.8, 72.7, 94.0, 109.1],
            backgroundColor: '#0e6f94',
          },
        ],
      },
      options: {
        // v3系はplugins/legendにする必要あり
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              barThickness: 30,
            },
          ],
        },
      },
    });

    //-----

    const ctrn = document.getElementById('contract-number');
    new Chart(ctrn, {
      // グラフの種類：折れ線グラフを指定
      type: 'bar',
      data: {
        // x軸の各メモリ
        labels: [
          '2017年',
          '2018年',
          '2019年',
          '2020年',
          '2021年',
          '2022年',
          '2023年',
        ],
        datasets: [
          {
            label: '年別累計成約件数',
            fill: false,
            data: [14, 45, 78, 129, 225, 307, 356],
            backgroundColor: '#0e6f94',
          },
        ],
      },
      options: {
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              barThickness: 30,
            },
          ],
        },
      },
    });

    //-----

    const prid = document.getElementById('project-industry');
    new Chart(prid, {
      type: 'pie',
      data: {
        // x軸の各メモリ
        labels: [
          'サービス関連',
          'AI・テクノロジー',
          'IT・インターネット関連',
          'エンタメ・アート・スポーツ・ファッション',
          'バイオ・ヘルスケア・医療関連',
          '製造業',
          '農業・フードテック',
          '社会課題解決',
          '小売業',
        ],
        datasets: [
          {
            label: '案件業種別割合',
            fill: true,
            data: [26.7, 15.6, 12.7, 11.4, 9.8, 7.5, 7.2, 5.9, 3.3],
            backgroundColor: [
              '#004466',
              '#0E6F94',
              '#519DC5',
              '#86CEF8',
              '#BAFFFF',
              '#636363',
              '#909090',
              '#C0C0C0',
              '#F3F3F3',
            ],
          },
        ],
      },
      options: {
        legend: {
          display: false,
        },
      },
    });

    //-----

    const prar = document.getElementById('project-area');
    new Chart(prar, {
      type: 'pie',
      data: {
        // x軸の各メモリ
        labels: [
          '東京都',
          '神奈川県',
          '大阪府',
          '福岡県',
          '埼玉県',
          '愛知県',
          '千葉県',
          'その他',
        ],
        datasets: [
          {
            label: '案件地域別割合',
            fill: false,
            data: [58.5, 8.1, 4.3, 3.5, 3.5, 3.2, 2.8, 16.2],
            backgroundColor: [
              '#004466',
              '#0E6F94',
              '#519DC5',
              '#86CEF8',
              '#BAFFFF',
              '#636363',
              '#909090',
              '#C0C0C0',
            ],
          },
        ],
      },
      options: {
        legend: {
          display: false,
        },
      },
    });

    //-----

    const cmpc = document.getElementById('community-participants');
    new Chart(cmpc, {
      // グラフの種類：折れ線グラフを指定
      type: 'bar',
      data: {
        // x軸の各メモリ
        labels: ['2021年', '2022年', '2023年'],
        datasets: [
          {
            label: '参加者数',
            data: [550, 7935, 12116],
            backgroundColor: '#0E6F94',
          },
        ],
      },
      options: {
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              barThickness: 60,
            },
          ],
          yAxes: [
            {
              ticks: {
                callback: function(value) {
                  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/, ',');
                },
              },
            },
          ],
        },
      },
    });

    //-----

    const ctrs = document.getElementById('contract-results');
    new Chart(ctrs, {
      // グラフの種類：折れ線グラフを指定
      type: 'bar',
      data: {
        // x軸の各メモリ
        labels: ['2021年', '2022年', '2023年'],
        datasets: [
          {
            label: '約定金額',
            data: [0, 18071, 3898],
            backgroundColor: '#0E6F94',
          },
        ],
      },
      options: {
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              barThickness: 60,
            },
          ],
          yAxes: [
            {
              ticks: {
                callback: function(value) {
                  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/, ',');
                },
              },
            },
          ],
        },
      },
    });

    //-----

    const rtrs = document.getElementById('fail-results');
    new Chart(rtrs, {
      // グラフの種類：折れ線グラフを指定
      type: 'bar',
      data: {
        // x軸の各メモリ
        labels: [
          '2017年',
          '2018年',
          '2019年',
          '2020年',
          '2021年',
          '2022年',
          '2023年',
        ],
        datasets: [
          {
            label: '倒産/解散件数',
            data: [0, 0, 1, 2, 3, 8, 6],
            backgroundColor: '#0E6F94',
            stack: 'Stack 0',
          },
        ],
      },
      options: {
        legend: {
          display: false,
        },
        scales: {
          x: {
            stacked: true,
          },
          yAxes: [
            {
              ticks: {
                min: 0,
                max: 8,
                stepSize: 1,
              },
            },
          ],
        },
      },
    });

    //-----

    const usre = document.getElementById('user-results');
    new Chart(usre, {
      // グラフの種類：折れ線グラフを指定
      type: 'bar',
      data: {
        // x軸の各メモリ
        labels: [
          '2017年',
          '2018年',
          '2019年',
          '2020年',
          '2021年',
          '2022年',
          '2023年',
        ],
        datasets: [
          {
            label: '年別累計ユーザー数',
            fill: false,
            data: [5625, 13709, 20432, 51588, 88582, 116991, 140718],
            backgroundColor: '#0E6F94',
          },
        ],
      },
      options: {
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              barThickness: 30,
            },
          ],
          yAxes: [
            {
              ticks: {
                callback: function(value) {
                  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/, ',');
                },
              },
            },
          ],
        },
      },
    });

    //-----

    const usag = document.getElementById('user-age');
    new Chart(usag, {
      type: 'pie',
      data: {
        // x軸の各メモリ
        labels: ['20代', '30代', '40代', '50代', '60代', '70代以上'],
        datasets: [
          {
            label: 'ユーザーの年代',
            fill: false,
            data: [4.3, 26.1, 34.9, 22.7, 9.8, 2.3],
            backgroundColor: [
              '#004466',
              '#0E6F94',
              '#519DC5',
              '#86CEF8',
              '#BAFFFF',
              '#EFFFFF',
            ],
          },
        ],
      },
      options: {
        legend: {
          display: false,
        },
      },
    });

    //-----

    const usar = document.getElementById('user-area');
    new Chart(usar, {
      type: 'pie',
      data: {
        // x軸の各メモリ
        labels: [
          '東京都',
          '神奈川県',
          '大阪府',
          '埼玉県',
          '愛知県',
          '千葉県',
          '兵庫県',
          'その他',
        ],
        datasets: [
          {
            label: 'ユーザーの居住地',
            fill: false,
            data: [28.7, 10.7, 7.0, 5.9, 5.8, 5.6, 4.0, 32.3],
            backgroundColor: [
              '#004466',
              '#0E6F94',
              '#519DC5',
              '#86CEF8',
              '#BAFFFF',
              '#636363',
              '#909090',
              '#C0C0C0',
            ],
          },
        ],
      },
      options: {
        legend: {
          display: false,
        },
      },
    });

    //-----

    const usob = document.getElementById('user-object');
    new Chart(usob, {
      type: 'pie',
      data: {
        // x軸の各メモリ
        labels: [
          '企業の成長を応援したいから',
          '長期投資を目的としたいから',
          '自らのポートフォリオに加えたいから',
          'より投資効率を上げたいから',
          '高い投資リターンを得るため',
          'その他',
        ],
        datasets: [
          {
            label: 'ユーザーの投資目的',
            fill: false,
            data: [39.2, 22.7, 22.2, 10.7, 3.8, 1.3],
            backgroundColor: [
              '#004466',
              '#0E6F94',
              '#519DC5',
              '#86CEF8',
              '#BAFFFF',
              '#C0C0C0',
            ],
          },
        ],
      },
      options: {
        legend: {
          display: false,
        },
      },
    });
  }
}
