// @flow
import React from 'react';
import PopularBanksView from './PopularBanksView';
import SelectBankView from './SelectBankView';
import SelectBranchView from './SelectBranchView';
import BankConfirmView from './BankConfirmView';
import BankClient from '../../models/banks/BankClient';
import type { Bank } from '../../models/banks/Bank';
import type { BankBranch } from '../../models/banks/BankBranch';

export const POPULAR_BANKS_VIEW = 0;
export const SELECT_BANK_VIEW = 1;
export const SELECT_BRANCH_VIEW = 2;
export const CONFIRM_VIEW = 3;

type Props = {
  bank: ?Bank,
  branch: ?BankBranch,
  onChangeBank: (bank: Bank) => void,
  onChangeBranch: (branch: BankBranch) => void,
  onOk: () => void,
  onCancel: () => void,
};

type State = {
  banks: Array<Bank>,
  view: number,
};

export default class BankModalView extends React.Component<Props, State> {
  bankClient = new BankClient();

  constructor(props: Props) {
    super(props);

    this.state = {
      banks: [],
      view: POPULAR_BANKS_VIEW,
    };
  }

  componentDidMount() {
    this.bankClient
      .fetchAll()
      .then(response => {
        this.setState({
          banks: response.data,
        });
      })
      .catch(error => {
        console.warn(error);
      });
  }

  onTransition(view: number) {
    this.setState({
      view: view,
    });
  }

  onChangeBank(bank: Bank) {
    this.props.onChangeBank(bank);
    this.setState({
      view: SELECT_BRANCH_VIEW,
    });
  }

  onChangeBranch(branch: BankBranch) {
    this.props.onChangeBranch(branch);
    this.setState({
      view: CONFIRM_VIEW,
    });
  }

  onOk() {
    this.props.onOk();
    this.setState({
      view: POPULAR_BANKS_VIEW,
    });
  }

  onCancel() {
    this.props.onCancel();
    this.setState({
      view: POPULAR_BANKS_VIEW,
    });
  }

  render() {
    const { bank, branch } = this.props;
    const { banks, view } = this.state;

    return (
      <div className="modal modal-fullscreen" id="bank_modal" tabIndex="-1">
        {view === POPULAR_BANKS_VIEW && (
          <PopularBanksView
            banks={banks}
            onTransition={view => this.onTransition(view)}
            onChangeBank={bank => this.onChangeBank(bank)}
            onCancel={() => this.onCancel()}
          />
        )}
        {view === SELECT_BANK_VIEW && (
          <SelectBankView
            banks={banks}
            onTransition={view => this.onTransition(view)}
            onChangeBank={bank => this.onChangeBank(bank)}
            onCancel={() => this.onCancel()}
          />
        )}
        {view === SELECT_BRANCH_VIEW && bank != null && (
          <SelectBranchView
            bank={bank}
            onTransition={view => this.onTransition(view)}
            onChangeBranch={branch => this.onChangeBranch(branch)}
            onCancel={() => this.onCancel()}
          />
        )}
        {view === CONFIRM_VIEW && bank != null && branch != null && (
          <BankConfirmView
            bank={bank}
            branch={branch}
            onTransition={view => this.onTransition(view)}
            onOk={() => this.onOk()}
            onCancel={() => this.onCancel()}
          />
        )}
      </div>
    );
  }
}
