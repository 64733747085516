// @flow
import JQueryView, { on } from '../../../common/JQueryView';

declare var $: any;

export default class TemplateView extends JQueryView {
  paragraphImages: Array<any>;

  constructor(paragraphImages: Array<string>) {
    super('.simple_form');
    this.paragraphImages = paragraphImages;
    this.render();
  }

  @on('change #company_ir_paragraph_image1')
  onChangeParagraphImage1(event: any) {
    this.renderImage(event, 1);
  }

  @on('change #company_ir_paragraph_image2')
  onChangeParagraphImage2(event: any) {
    this.renderImage(event, 2);
  }

  @on('change #company_ir_paragraph_image3')
  onChangeParagraphImage3(event: any) {
    this.renderImage(event, 3);
  }

  @on('change #company_ir_paragraph_image4')
  onChangeParagraphImage4(event: any) {
    this.renderImage(event, 4);
  }

  @on('change #company_ir_paragraph_image5')
  onChangeParagraphImage5(event: any) {
    this.renderImage(event, 5);
  }

  @on('change #company_ir_paragraph_image6')
  onChangeParagraphImage6(event: any) {
    this.renderImage(event, 6);
  }

  @on('change #company_ir_paragraph_image7')
  onChangeParagraphImage7(event: any) {
    this.renderImage(event, 7);
  }

  @on('change #company_ir_paragraph_image8')
  onChangeParagraphImage8(event: any) {
    this.renderImage(event, 8);
  }

  renderImage(event: any, imageNumber: number) {
    const file = $(event.target).prop('files')[0];
    const fr = new FileReader();
    fr.onload = () => {
      this.paragraphImages[imageNumber - 1] = fr.result;
      this.render();
    };
    fr.readAsDataURL(file);
  }

  @on('click .remove_fields')
  onClickRemoveFields(event: any) {
    $(event.target)
      .prev('input[type=hidden]')
      .val('1');
    $(event.target)
      .closest('fieldset')
      .hide();
    event.preventDefault();
  }

  @on('click .add_fields')
  onClickAddFields(event: any) {
    const time = new Date().getTime();
    const regexp = new RegExp($(event.target).data('id'), 'g');
    $(event.target).before(
      $(event.target)
        .data('fields')
        .replace(regexp, time),
    );
    event.preventDefault();
  }

  render() {
    for (let i = 1; i < this.paragraphImages.length + 1; i++) {
      if (this.paragraphImages[i - 1] !== '') {
        $('#preview_image' + i)
          .css('background-image', `url(${this.paragraphImages[i - 1]})`)
          .show();
      }
    }
  }
}
