// @flow
import { transform } from 'lodash';

export function deepMapKeys(obj: Object, mapFunc: Function) {
  return transform(obj, (r, v, k) => {
    const value = v instanceof Object ? deepMapKeys(v, mapFunc) : v;
    r[mapFunc(k)] = value;
  });
}

export function delimited(n: ?number): string {
  if (n == null) {
    return '';
  }
  return String(n).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
}

export function numberToCurrency(n: ?number): string {
  if (n == null) {
    return '0円';
  }
  return delimited(n) + '円';
}
