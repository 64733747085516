// @flow
export const STATUS = {
  inProgress: 0,
  failed: 1,
  canceled: 2,
  succeeded: 3,
  executed: 4,
  abort: 5,
};

export const ANGEL_TYPE = {
  none: 0,
  typeA: 1,
  typeB: 2,
  typeBOtherwiseA: 3,
  typeAAndPreSeed: 4,
  typeBAndPreSeed: 5,
  typeAOtherwisePreSeed: 6,
  typeBOtherwiseAOrPreSeed: 7,
  typeBOtherwiseAAndPreSeed: 8,
  typeBOtherwisePreSeed: 9,
};

export const STOCK_TYPE = {
  commonStock: 0,
  stockOption: 1,
};

export const SURPLUS = {
  nothing: 0,
  exist: 1,
  expecting: 2,
};

export const PATENT = {
  nothing: 0,
  exist: 1,
  pending: 2,
  transferring: 3,
  exist_president: 4,
  pending_president: 5,
};

export const SHAREHOLDER_BENEFIT = {
  nothing: 0,
  for_stockholder: 1,
  for_optionholder: 2,
};

export const EDITOR = {
  normal: 0,
  block: 1,
};

// TODO 一時的に分岐
// https://fundinno.com/projects/512 用に追加したが後ほど削除
export const TEMP_FUNDINNO_3301_PROJECT = {
  id: 512,
};

export type Project = {
  id: number,
  name: string,
  original_company_name: string,
  short_description: string,
  status: number,
  solicit: number,
  solicit_limit: number,
  solicited: number,
  specific_solicit_limit: number,
  specific_solicited: number,
  angel_type: number,
  shareholder_benefit: boolean,
  is_succeeded_with_limit: boolean,
  image: any,
  products_size: number,
  case_studies_size: number,
  investors_size: number,
  waiting_wait_order: boolean,
  wait_remain_time: string,
  start_at: string,
  finish_at: string,
  failed_at: string,
  succeeded_at: string,
  execution_at: string,
  execution_price: ?number,
  succeeded_price: ?number,
  deliv_at: ?string,
  deliv_price: ?number,
  company: any,
  stock_type: number,
  editor_version: number,
  description_vc_invested: boolean,
  description_company_invested: boolean,
  description_angel_invested: boolean,
  description_fundinno_funded: boolean,
  description_surplus: number,
  description_service_launched: boolean,
  description_patent: number,
  description_angel_type: number,
  description_shareholder_benefit: number,
  description_stock_type: number,
  is_withdraw: boolean,
  withdraw_reason: string,
  keywords: any,
};
