// @flow
import JQueryView, { on } from '../../../../common/JQueryView';

declare var $: any;

export default class ProcedureCheckView extends JQueryView {
  constructor() {
    super('.my.investors.specific_applications.users.renewal_application');

    this.render();
  }

  @on('change #consent')
  onChangeConsent() {
    this.render();
  }

  render() {
    if ($('#consent').prop('checked')) {
      $('#btn-submit').prop('disabled', false);
    } else {
      $('#btn-submit').prop('disabled', true);
    }
  }
}
