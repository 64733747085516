// @flow
import React from 'react';
import type { Category } from '../../models/categories/Category';
import type { Area } from '../../models/companies/areas/Area';
import type { Feature } from '../../models/companies/features/Feature';
import type { EcfStage } from '../../models/companies/ecf_stages/EcfStage';
import type { CompanyProperty } from '../../models/companies/Company';

type Props = {
  name: string,
  items: $ReadOnlyArray<Category | Area | Feature | EcfStage | CompanyProperty>,
  activeItemId: ?number,
  isOpen: boolean,
  className: string,
  onClick: () => void,
  onClickItem: (?number) => void,
};

export function ConditionList({
  name,
  items,
  activeItemId,
  isOpen,
  className,
  onClick,
  onClickItem,
}: Props) {
  return (
    <div className={`yama-list-group ${className} mb-3`}>
      <a
        className="list-group-item list-group-item-action d-flex justify-content-between align-items-center"
        onClick={onClick}
      >
        {name}
        <i className="fas fa-angle-down float-right" />
      </a>
      {isOpen &&
        items.map(item => (
          <a
            key={item.id}
            className={`list-group-item list-group-item-action d-flex justify-content-between align-items-center ${
              activeItemId === item.id ? 'active' : ''
            }`}
            onClick={() =>
              onClickItem(item.id === activeItemId ? null : item.id)
            }
          >
            {item.name}
            <i className="fas fa-angle-right" />
          </a>
        ))}
    </div>
  );
}
