// @flow
import JQueryView, { on } from '../common/JQueryView';

export default class BundleView extends JQueryView {
  constructor() {
    super('body');

    $(window).scroll(function() {
      $(window).scrollTop() > 0
        ? $('.g-header').addClass('is-fixed')
        : $('.g-header').removeClass('is-fixed');
    });
  }

  @on('click #menu-button')
  onClickMenuButton() {
    $('#dropdown-menu').toggleClass('hidden');
    $('#overlay').toggleClass('hidden');
    $('body').toggleClass('is-fixed');
  }

  @on('click #overlay')
  onClickOverLay() {
    $('#dropdown-menu').toggleClass('hidden');
    $('#overlay').toggleClass('hidden');
    $('body').removeClass('is-fixed');
  }

  @on('click #g-notification-dissmis')
  onClickNotificationDissmis() {
    return (
      $('#g-notification-dissmis')
        .parent()
        .hide(),
      !1
    );
  }
}
