// @flow
import JQueryView, { on } from '../../../../common/JQueryView';

declare var $: any;

export default class FormView extends JQueryView {
  constructor() {
    super('.simple_form');

    this.render();
  }

  @on('change input[type="radio"]')
  onChangeRadioButtons() {
    this.render();
  }

  @on('change input[type="checkbox"]')
  onChangeCheckBoxes() {
    this.render();
  }

  canSubmit() {
    return (
      6 <=
      $('input[type="checkbox"]:checked').length +
        $('input[type="radio"]:checked').length
    );
  }

  render() {
    if (this.canSubmit()) {
      $('#btn-submit').prop('disabled', false);
    } else {
      $('#btn-submit').prop('disabled', true);
    }
  }
}
