// @flow
import React from 'react';
import ProjectsListGroupItem from './ProjectsListGroupItem';

type Props = {
  order: ?string,
  keyword: string,
  onChangeKeyword: (e: any) => void,
  onClickOrder: (order: ?string) => void,
  onClickKeywordSearch: () => void,
  onSubmitSearch: () => void,
};

type State = {
  isOpen: boolean,
};

export default class ProjectsSelector extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  toggleOpen() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  render() {
    const {
      order,
      keyword,
      onChangeKeyword,
      onClickOrder,
      onClickKeywordSearch,
      onSubmitSearch,
    } = this.props;
    return (
      <React.Fragment>
        <div className="list-group project-selector__navi">
          <ProjectsListGroupItem
            key="order-0"
            isActive={order === 'in_progress'}
            name="募集中の投資先"
            className="list-group-item-action justify-content-between d-flex"
            onClick={() => onClickOrder('in_progress')}
          />
          <ProjectsListGroupItem
            key="order-1"
            isActive={order == null}
            name="全て"
            className="list-group-item-action justify-content-between d-flex"
            onClick={() => onClickOrder(null)}
          />
        </div>
        <div className="list-group project-selector__search">
          <div className="search-wrapper">
            <form acceptCharset="UTF-8" method="get" onSubmit={onSubmitSearch}>
              <div className="input-group search-keyword">
                <input
                  type="text"
                  className="form-control"
                  name="keyword"
                  maxLength="50"
                  value={keyword}
                  onChange={onChangeKeyword}
                  placeholder="キーワードで検索"
                />
                <span className="search-icon" onClick={onClickKeywordSearch} />
              </div>
            </form>
          </div>
        </div>
        <div className="project-selector__attention">
          <span>※</span>
          <span>
            募集ページについては、募集期間終了後、募集の成立不成立に関わらず事業者の要請に応じて非開示となることがあります。ご了承下さい。
          </span>
        </div>
      </React.Fragment>
    );
  }
}
