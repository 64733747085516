// @flow
import React from 'react';
import {
  Button,
  FormFeedback,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import { ConditionClient } from '../../../../models/companies/kyccs/ConditionClient';

type Props = {
  isOpen: boolean,
  kyccId: number,
  conditionId: number,
  note: string,
  onClose: () => void,
  onChangeNote: string => void,
};

type State = {
  note: string,
  updating: boolean,
  error: ?string,
};

export class EditNoteModal extends React.Component<Props, State> {
  conditionClient = new ConditionClient();

  constructor(props: Props) {
    super(props);

    this.state = {
      note: props.note,
      updating: false,
      error: null,
    };
  }

  onClickSubmit() {
    const { kyccId, conditionId, onChangeNote } = this.props;
    const { note } = this.state;

    this.setState({ updating: true, error: null }, () => {
      this.conditionClient
        .updateNote(kyccId, conditionId, note)
        .then(response => {
          if (response.data.note != null) {
            onChangeNote(response.data.note);
          }
        })
        .catch(error => {
          if (error.response.status === 422) {
            this.setState({
              error: error.response.data.note[0],
            });
          } else {
            console.warn(error);
          }
        });
    });
  }

  render() {
    const { isOpen, onClose } = this.props;
    const { note, error } = this.state;
    const closeBtn = (
      <button className="close" onClick={onClose}>
        &times;
      </button>
    );

    return (
      <Modal isOpen={isOpen} toggle={onClose}>
        <ModalHeader toggle={onClose} close={closeBtn}>
          メモの編集
        </ModalHeader>
        <ModalBody>
          <div className="mb-4">
            <Input
              type="textarea"
              value={note}
              placeholder="30文字以内で入力してください"
              invalid={error != null}
              rows={8}
              onChange={event => this.setState({ note: event.target.value })}
            />
            {error != null && <FormFeedback>{error}</FormFeedback>}
          </div>
          <Button
            color="primary"
            size="lg"
            block
            onClick={() => this.onClickSubmit()}
          >
            保存
          </Button>
        </ModalBody>
      </Modal>
    );
  }
}
