// @flow
import JQueryView, { on } from '../../common/JQueryView';
import { JOB, BUSINESS } from '../../../models/users/User';

declare var $: any;

export default class FormView extends JQueryView {
  edit: boolean;

  constructor(edit: boolean) {
    super('#my_investor_form_view');
    this.edit = edit;
    $('#user_zip_code').jpostal({
      postcode: ['#user_zip_code'],
      address: {
        '#user_prefecture_id': '%3',
        '#user_city': '%4',
        '#user_address1': '%5',
      },
    });

    $('#user_birth_on_1i').after('<span class="ml-5 mr-10">年</span>');
    $('#user_birth_on_2i').after('<span class="ml-5 mr-10">月</span>');
    $('#user_birth_on_3i').after('<span class="ml-5 mr-10">日</span>');

    this.render();
  }

  @on('change #user_zip_code')
  onChangeUserZipCode() {
    this.render();
  }

  @on('change #user_address2')
  onChangeUserAddress2() {
    this.render();
  }

  @on('change #user_job')
  onChangeJob() {
    this.render();
  }

  @on('change #user_business')
  onChangeBusiness() {
    this.render();
  }

  @on('keyup #user_first_name_kana')
  onKeyupFirstNameKana() {
    $('#user_bank_account_name').val(
      $('#user_last_name_kana').val() + $('#user_first_name_kana').val(),
    );
  }

  @on('keyup #user_last_name_kana')
  onKeyupLastNameKana() {
    $('#user_bank_account_name').val(
      $('#user_last_name_kana').val() + $('#user_first_name_kana').val(),
    );
  }

  @on('blur #user_zip_code')
  onBlurZipCode() {
    const original_zip_code = $('#user_zip_code').val();
    if (original_zip_code.length === 0) {
      return;
    }

    let formatted_zip_code = original_zip_code
      .replace('ー', '-')
      .replace('－', '-')
      .replace('−', '-')
      .replace('ｰ', '-')
      .replace(/[０-９]/g, s => String.fromCharCode(s.charCodeAt(0) - 0xfee0));

    if (/^\d*$/.test(formatted_zip_code) === true) {
      if (formatted_zip_code.length >= 4) {
        formatted_zip_code =
          formatted_zip_code.substr(0, 3) + '-' + formatted_zip_code.substr(3);
      }
    }

    // change()を呼び出すことで最終的に住所の自動入力が走ります
    if (formatted_zip_code !== original_zip_code) {
      $('#user_zip_code')
        .val(formatted_zip_code)
        .change();
    }
  }

  render() {
    if ($('#user_zip_code').val() !== '' && $('#user_address2').val() === '') {
      $('#address2-notice').show();
      $('#user_address2').addClass('address-em');
    } else {
      $('#address2-notice').hide();
      $('#user_address2').removeClass('address-em');
    }

    if (this.edit === true) {
      $('#address2-notice').hide();
      $('#user_address2').removeClass('address-em');
    }

    const userJob = parseInt($('#user_job').val());
    if (userJob === JOB.others) {
      $('.user_job_details').show();
    } else {
      $('.user_job_details').hide();
    }

    if (
      userJob === JOB.homemaker ||
      userJob === JOB.student ||
      userJob === JOB.unemployed
    ) {
      $('.user_business').hide();
    } else {
      $('.user_business').show();
    }

    const userBusiness = parseInt($('#user_business').val());
    if (userBusiness === BUSINESS.others) {
      $('.user_business_details').show();
    } else {
      $('.user_business_details').hide();
    }
  }
}
