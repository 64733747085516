// @flow
import JQueryView, { on } from '../../../common/JQueryView';

export default class MediaView extends JQueryView {
  others: boolean;

  constructor() {
    super('.order-body');
  }

  @on('click a.disabled')
  onClickDisabledLink() {
    return false;
  }
}
