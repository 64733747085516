// @flow
import JQueryView, { on } from '../../../common/JQueryView';

declare var $: any;

export default class FormView extends JQueryView {
  constructor() {
    super('#form_view');
    $(document).ready(() => {
      this.render();
    });
  }

  @on('change .zip-code-field')
  onChangeUserZipCode() {
    this.render();
  }

  @on('blur .zip-code-field')
  onBlurZipCode(event: any) {
    const original_zip_code = $(event.target).val();
    if (original_zip_code.length === 0) {
      return;
    }

    let formatted_zip_code = original_zip_code
      .replace('ー', '-')
      .replace('－', '-')
      .replace('−', '-')
      .replace('ｰ', '-')
      .replace(/[０-９]/g, s => String.fromCharCode(s.charCodeAt(0) - 0xfee0));

    if (/^\d*$/.test(formatted_zip_code) === true) {
      if (formatted_zip_code.length >= 4) {
        formatted_zip_code =
          formatted_zip_code.substr(0, 3) + '-' + formatted_zip_code.substr(3);
      }
    }

    // change()を呼び出すことで最終的に住所の自動入力が走ります
    if (formatted_zip_code !== original_zip_code) {
      $(event.target)
        .val(formatted_zip_code)
        .change();
    }
  }

  @on('click #delete-beneficial-owner')
  deleteBeneficialOwner(event: any) {
    event.stopPropagation();

    const index = $(event.target).data('index');
    $(`#beneficial-owner-${index}`).remove();
    $(`#investor_corporate_beneficial_owners_attributes_${index}__destroy`).val(
      'true',
    );

    $('#add-beneficial-owner')[0].dataset.params = `index=${index - 1}`;

    this.render();
  }

  @on('change .relationship-with-corporation')
  onChangeRelationshipWithCorporation(event: any) {
    this.toggleRelationshipWithCorporationSubFields(event.target);
  }

  toggleRelationshipWithCorporationSubFields(target: any) {
    const value = $(target).val();
    const index = this.getIndex(target);
    const position = $(`#beneficial-owner-${index}-position`);
    const ownershipRatio = $(`#beneficial-owner-${index}-ownership-ratio`);
    if (value === 'influence') {
      position.removeClass('d-none');
      ownershipRatio.addClass('d-none');
      this.togglePositionDescription(target);
    } else if (value === 'voting') {
      position.addClass('d-none');
      ownershipRatio.removeClass('d-none');
    } else {
      position.addClass('d-none');
      ownershipRatio.addClass('d-none');
    }
  }

  getIndex(target: any) {
    const parent = this.parent(target);
    const index = parent.data('index');
    return index;
  }

  parent(target: any) {
    return $(target).parents('.beneficial-owner');
  }

  @on('change .position-field:radio')
  onChangePosition(event: any) {
    this.togglePositionDescription(event.target);
  }

  togglePositionDescription(target: any) {
    const value = $(target).val();
    const parent = $(target).parents('.beneficial-owner');
    const description = parent.find('.position-description-field');

    if (value === 'other') {
      description.show();
    } else {
      description.hide();
    }
  }

  render() {
    if ($('.beneficial-owner').length >= 2) {
      // 削除ボタン設置
      const index = $('.beneficial-owner').length - 1; // 0からインデックスが始まるので -1 する
      const deleteButton = `<button id="delete-beneficial-owner" class="btn btn-danger" data-index="${index}">削除する</button>`;
      $('.delete-beneficial-owner-area')
        .last()
        .html(deleteButton);
    }

    $('.relationship-with-corporation').each((_, dom) =>
      this.toggleRelationshipWithCorporationSubFields(dom),
    );

    $('.position-field:checked').each((_, dom) =>
      this.togglePositionDescription(dom),
    );
  }
}
