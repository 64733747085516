// @flow
import React from 'react';
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
} from 'reactstrap';
import type { Company } from '../../../../../models/companies/kyccs/Kycc';
import { ConditionClient } from '../../../../../models/companies/kyccs/ConditionClient';
import { ConditionResults } from './ConditionResults';

type Props = {
  company: Company,
};

type State = {
  company: Company,
};

export class Results extends React.Component<Props, State> {
  conditionClient = new ConditionClient();

  constructor(props: Props) {
    super(props);

    this.state = {
      company: props.company,
    };
  }

  render() {
    const { company } = this.state;

    return (
      <Card className="border-light results">
        <CardHeader>
          <CardTitle className="text-center">
            <h1>検索が完了しました</h1>
          </CardTitle>
        </CardHeader>
        <CardBody className="pt-0">
          {company.kyccCheckedCount >= company.kyccMonthlyLimit && (
            <Alert color="success">
              今月の検索可能件数の上限に到達しました。
              <br />
              詳細はFUNDINNOお問い合わせフォーム（https://fundinno.com/contanct）よりご連絡をお願いいたします。
            </Alert>
          )}
          <div className="clearfix mb-4">
            <div className="well float-right">
              サービス提供元
              <br />
              ＫＹＣコンサルティング株式会社
              <br />
              〒100-0006
              <br />
              東京都千代田区有楽町1-6-3  日比谷頴川ビル6階
            </div>
          </div>
          {company.conditions.map((condition, i) => (
            <ConditionResults key={i} condition={condition} />
          ))}
        </CardBody>
        <CardFooter>
          <div className="text-muted mb-4">
            ●本サービスを通じて当社が提供する情報は、当社が独自に情報を収集した結果に基づくものであり、最終的・絶対的な情報を提供するものではありません。
            <br />
            ●本サービスで敵供される情報は、同名であっても同一性を保証するものではありません。
            <br />
            ●本サービスで提供された情報を第三者に漏らすことを禁止します。またその情報で自己のデータベースを構築することを禁止します。
          </div>
          <div className="text-center mt-4">
            <Button
              color="primary"
              size="lg"
              onClick={() => {
                window.location.href = '/my/company/kycc';
              }}
            >
              反社チェックトップへ戻る
            </Button>
          </div>
        </CardFooter>
      </Card>
    );
  }
}
