// @flow
import JQueryView, { on } from '../../../../common/JQueryView';

declare var $: any;

export default class ConfirmView extends JQueryView {
  constructor() {
    super('.my.investors.specific_applications.corporates.confirm');

    this.render();
  }

  @on('change #check-consent')
  onChangeCheckConsent() {
    this.render();
  }

  @on('change #check-in-july')
  onChangeCheckInJuly() {
    this.render();
  }

  @on('click #complete-link')
  onCompleteLink() {
    $('#complete-link').addClass('disabled');
  }

  render() {
    // 8月1日以降の更新のチェックボックスがなければチェック扱いとする
    const isCheckedInJuly =
      $('#check-in-july').prop('checked') || $('#check-in-july').length === 0;

    if ($('#check-consent').prop('checked') && isCheckedInJuly) {
      $('#complete-link').removeClass('disabled');
    } else {
      $('#complete-link').addClass('disabled');
    }
  }
}
