// @flow
import React from 'react';

type Props = {
  isActive?: ?boolean,
  name?: string,
  children?: any,
  onClick: () => void,
};

export default function ProjectsListGroupItem(props: Props) {
  const { isActive, name, children, onClick } = props;
  const activeClass = isActive ? 'active' : '';

  return (
    <a
      className={`list-group-item list-group-item-action d-flex justify-content-between align-items-center ${activeClass}`}
      onClick={onClick}
    >
      {name || children}
    </a>
  );
}
