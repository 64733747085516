// @flow
import React from 'react';
import moment from 'moment';
import type { Project } from '../../models/projects/Project';
import { STATUS } from '../../models/projects/Project';
import { delimited } from '../../helpers/ApplicationHelper';

type Props = {
  project: Project,
};

export default function CardSpecificInvestor(props: Props) {
  const { project } = props;

  if (
    project.status === STATUS.inProgress &&
    moment().isAfter(moment(project.start_at)) &&
    project.products_size > 0 &&
    project.specific_solicit_limit
  ) {
    return (
      <div className="specific-investor container">
        <div className="row d-flex align-items-center bg-lightcyan mb-20 pt-10 pb-10">
          <div className="col-6 font-weight-bold">特定投資家枠</div>
          <div className="col-6 text-right solicit-limit">
            <span>{delimited(project.specific_solicit_limit)}円</span>
          </div>
        </div>
      </div>
    );
  } else {
    return <div />;
  }
}
