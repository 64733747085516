// @flow
import JQueryView, { on } from '../../common/JQueryView';
import moment from 'moment';

declare var $: any;

export default class FormView extends JQueryView {
  owned_share: number;
  expired_at: string;
  share_unit: number;
  can_buy_trade: boolean;

  constructor(
    owned_share: number,
    expired_at: string,
    share_unit: number,
    can_buy_trade: boolean,
  ) {
    super('.simple_form');
    this.owned_share = owned_share;
    this.expired_at = expired_at;
    this.share_unit = share_unit;
    this.can_buy_trade = can_buy_trade;
    $('.form_datetime').datetimepicker({
      locale: 'ja',
      format: 'YYYY/MM/DD',
      dayViewHeaderFormat: 'YYYY年 MM月',
      sideBySide: true,
      minDate: moment()
        .add(30, 'days')
        .startOf('day'),
      maxDate: moment().add(365, 'days'),
    });
    $('#company_shareholder_community_trade_expired_at').val(this.expired_at);
    this.render();
  }

  @on('keyup #company_shareholder_community_trade_select_type_0')
  onKeyupCompanyShareholderCommunityTradeSelectType0() {
    this.render();
  }

  @on('click #company_shareholder_community_trade_select_type_0')
  onClickCompanyShareholderCommunityTradeSelectType0() {
    this.render();
  }

  @on('keyup #company_shareholder_community_trade_select_type_1')
  onKeyupCompanyShareholderCommunityTradeSelectType1() {
    this.render();
  }

  @on('click #company_shareholder_community_trade_select_type_1')
  onClickCompanyShareholderCommunityTradeSelectType1() {
    this.render();
  }

  @on('keyup #company_shareholder_community_trade_unit_count')
  onKeyupCompanyShareholderCommunityTradeAmount() {
    this.render();
  }

  @on('click #company_shareholder_community_trade_unit_count')
  onClickCompanyShareholderCommunityTradeAmount() {
    this.render();
  }

  @on('keyup #company_shareholder_community_trade_unit_price')
  onKeyupCompanyShareholderCommunityTradeUnitPrice() {
    this.render();
  }

  @on('click #company_shareholder_community_trade_unit_price')
  onClickCompanyShareholderCommunityTradeUnitPrice() {
    this.render();
  }

  render() {
    const unitCount = parseInt(
      $('#company_shareholder_community_trade_unit_count').val(),
    );
    const unitPrice = parseInt(
      $('#company_shareholder_community_trade_unit_price').val(),
    );
    if (isNaN(this.share_unit * unitCount)) {
      $('#calc_share_amount').html('-');
    } else {
      $('#calc_share_amount').html(
        (this.share_unit * unitCount).toLocaleString().toString(),
      );
    }

    if (isNaN(this.share_unit * unitCount * unitPrice)) {
      $('#calc_price').html('-');
    } else {
      $('#calc_price').html(
        (this.share_unit * unitCount * unitPrice).toLocaleString().toString(),
      );
    }

    if (this.owned_share == null || this.owned_share < this.share_unit) {
      $('#company_shareholder_community_trade_select_type_1').attr(
        'disabled',
        true,
      );
      $('#attention').show();
    } else {
      $('#attention').hide();
    }

    if (!this.can_buy_trade) {
      $('#company_shareholder_community_trade_select_type_0').attr(
        'disabled',
        true,
      );
      $('#buy_trade_lock').show();
    } else {
      $('#buy_trade_lock').hide();
    }
  }
}
