// @flow
import JQueryView, { on } from '../common/JQueryView';

declare var $: any;

export default class AboutShareholderCommunityView extends JQueryView {
  constructor() {
    super('.about_shareholder_community');
  }

  @on('click .toggle-wrapper')
  onClickToggleAnswer(event: any) {
    const id = event.currentTarget.dataset.answerId;
    $(`#${id}`).slideToggle();
    $(event.currentTarget)
      .find('i')
      .toggleClass('fa-plus');
    $(event.currentTarget)
      .find('i')
      .toggleClass('fa-minus');
  }
}
