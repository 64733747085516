// @flow
import AxiosClient from '../../AxiosClient';
import type { AxiosPromise } from 'axios';
import type { Condition } from './Condition';

export class ConditionClient extends AxiosClient {
  updateNote(
    kyccId: number,
    conditionId: number,
    note: string,
  ): AxiosPromise<Condition> {
    return this.buildCamelCaseClient().put(
      `/api/v1/my/company/kyccs/${kyccId}/conditions/${conditionId}.json`,
      { condition: { note: note } },
    );
  }
}
