// @flow
import JQueryView, { on } from '../../../common/JQueryView';

declare var $: any;

export default class FormView extends JQueryView {
  convertApplication: string;
  necessaryPaper: string;
  necessaryPaper2: string;
  necessaryPaper3: string;
  necessaryPaper4: string;
  necessaryPaper5: string;

  constructor(
    convertApplication: string,
    necessaryPaper: string,
    necessaryPaper2: string,
    necessaryPaper3: string,
    necessaryPaper4: string,
    necessaryPaper5: string,
  ) {
    super('.simple_form');

    this.convertApplication = convertApplication;
    this.necessaryPaper = necessaryPaper;
    this.necessaryPaper2 = necessaryPaper2;
    this.necessaryPaper3 = necessaryPaper3;
    this.necessaryPaper4 = necessaryPaper4;
    this.necessaryPaper5 = necessaryPaper5;

    this.render();
  }

  @on('change #investor_specific_application_convert_application')
  onChangeConvertApplication(event: any) {
    if (!event.target.files.length) {
      return;
    }

    const file = $(event.target).prop('files')[0];

    if (this.fileTypeMatched(file.type)) {
      this.convertApplication = file.name;
      this.render();

      $('#investor_specific_application_remove_convert_application').val('');
    }
  }

  @on('change #investor_specific_application_necessary_paper')
  onChangeNecessaryPaper(event: any) {
    if (!event.target.files.length) {
      return;
    }

    const file = $(event.target).prop('files')[0];

    if (this.fileTypeMatched(file.type)) {
      this.necessaryPaper = file.name;
      this.render();

      $('#investor_specific_application_remove_necessary_paper').val('');
    }
  }

  @on('change #investor_specific_application_necessary_paper_2')
  onChangeNecessaryPaper2(event: any) {
    if (!event.target.files.length) {
      return;
    }

    const file = $(event.target).prop('files')[0];

    if (this.fileTypeMatched(file.type)) {
      this.necessaryPaper2 = file.name;
      this.render();

      $('#investor_specific_application_remove_necessary_paper_2').val('');
    }
  }

  @on('change #investor_specific_application_necessary_paper_3')
  onChangeNecessaryPaper3(event: any) {
    if (!event.target.files.length) {
      return;
    }

    const file = $(event.target).prop('files')[0];

    if (this.fileTypeMatched(file.type)) {
      this.necessaryPaper3 = file.name;
      this.render();

      $('#investor_specific_application_remove_necessary_paper_3').val('');
    }
  }

  @on('change #investor_specific_application_necessary_paper_4')
  onChangeNecessaryPaper4(event: any) {
    if (!event.target.files.length) {
      return;
    }

    const file = $(event.target).prop('files')[0];

    if (this.fileTypeMatched(file.type)) {
      this.necessaryPaper4 = file.name;
      this.render();

      $('#investor_specific_application_remove_necessary_paper_4').val('');
    }
  }

  @on('change #investor_specific_application_necessary_paper_5')
  onChangeNecessaryPaper5(event: any) {
    if (!event.target.files.length) {
      return;
    }

    const file = $(event.target).prop('files')[0];

    if (this.fileTypeMatched(file.type)) {
      this.necessaryPaper5 = file.name;
      this.render();

      $('#investor_specific_application_remove_necessary_paper_5').val('');
    }
  }

  fileTypeMatched(type: string) {
    if (
      type === 'application/pdf' ||
      type.indexOf('image/') === 0 ||
      type ===
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
      type === 'application/vnd.ms-excel' ||
      type === 'application/zip'
    ) {
      return true;
    } else {
      return false;
    }
  }

  @on('click #remove_convert_application_button')
  onClickRemoveConvertApplication() {
    $('#investor_specific_application_convert_application').val('');
    this.convertApplication = '';
    $('#remove_convert_application_button').hide();
    $('#investor_specific_application_remove_convert_application').val('1');
    this.render();
  }

  @on('click #remove_necessary_paper_button')
  onClickRemoveNecessaryPaper() {
    $('#investor_specific_application_necessary_paper').val('');
    this.necessaryPaper = '';
    $('#remove_necessary_paper_button').hide();
    $('#investor_specific_application_remove_necessary_paper').val('1');
    this.render();
  }

  @on('click #remove_necessary_paper_2_button')
  onClickRemoveNecessaryPaper2() {
    $('#investor_specific_application_necessary_paper_2').val('');
    this.necessaryPaper2 = '';
    $('#remove_necessary_paper_2_button').hide();
    $('#investor_specific_application_remove_necessary_paper_2').val('1');
    this.render();
  }

  @on('click #remove_necessary_paper_3_button')
  onClickRemoveNecessaryPaper3() {
    $('#investor_specific_application_necessary_paper_3').val('');
    this.necessaryPaper3 = '';
    $('#remove_necessary_paper_3_button').hide();
    $('#investor_specific_application_remove_necessary_paper_3').val('1');
    this.render();
  }

  @on('click #remove_necessary_paper_4_button')
  onClickRemoveNecessaryPaper4() {
    $('#investor_specific_application_necessary_paper_4').val('');
    this.necessaryPaper4 = '';
    $('#remove_necessary_paper_4_button').hide();
    $('#investor_specific_application_remove_necessary_paper_4').val('1');
    this.render();
  }

  @on('click #remove_necessary_paper_5_button')
  onClickRemoveNecessaryPaper5() {
    $('#investor_specific_application_necessary_paper_5').val('');
    this.necessaryPaper5 = '';
    $('#remove_necessary_paper_5_button').hide();
    $('#investor_specific_application_remove_necessary_paper_5').val('1');
    this.render();
  }

  render() {
    if (this.convertApplication === '') {
      $('#preview_convert_application')
        .empty()
        .css('height', 'auto')
        .addClass('file-empty')
        .append('ファイルが選択されていません');
      $('#remove_convert_application_button').hide();
    } else {
      $('#preview_convert_application')
        .empty()
        .css('height', 'auto')
        .removeClass('file-empty')
        .append(this.convertApplication);
      $('#remove_convert_application_button').show();
    }

    if (this.necessaryPaper === '') {
      $('#preview_necessary_paper')
        .empty()
        .css('height', 'auto')
        .addClass('file-empty')
        .append('ファイルが選択されていません');
      $('#remove_necessary_paper_button').hide();
    } else {
      $('#preview_necessary_paper')
        .empty()
        .css('height', 'auto')
        .removeClass('file-empty')
        .append(this.necessaryPaper);
      $('#remove_necessary_paper_button').show();
    }

    if (this.necessaryPaper2 === '') {
      $('#preview_necessary_paper_2')
        .empty()
        .css('height', 'auto')
        .addClass('file-empty')
        .append('ファイルが選択されていません');
      $('#remove_necessary_paper_2_button').hide();
    } else {
      $('#preview_necessary_paper_2')
        .empty()
        .css('height', 'auto')
        .removeClass('file-empty')
        .append(this.necessaryPaper2);
      $('#remove_necessary_paper_2_button').show();
    }

    if (this.necessaryPaper3 === '') {
      $('#preview_necessary_paper_3')
        .empty()
        .css('height', 'auto')
        .addClass('file-empty')
        .append('ファイルが選択されていません');
      $('#remove_necessary_paper_3_button').hide();
    } else {
      $('#preview_necessary_paper_3')
        .empty()
        .css('height', 'auto')
        .removeClass('file-empty')
        .append(this.necessaryPaper3);
      $('#remove_necessary_paper_3_button').show();
    }

    if (this.necessaryPaper4 === '') {
      $('#preview_necessary_paper_4')
        .empty()
        .css('height', 'auto')
        .addClass('file-empty')
        .append('ファイルが選択されていません');
      $('#remove_necessary_paper_4_button').hide();
    } else {
      $('#preview_necessary_paper_4')
        .empty()
        .css('height', 'auto')
        .removeClass('file-empty')
        .append(this.necessaryPaper4);
      $('#remove_necessary_paper_4_button').show();
    }

    if (this.necessaryPaper5 === '') {
      $('#preview_necessary_paper_5')
        .empty()
        .css('height', 'auto')
        .addClass('file-empty')
        .append('ファイルが選択されていません');
      $('#remove_necessary_paper_5_button').hide();
    } else {
      $('#preview_necessary_paper_5')
        .empty()
        .css('height', 'auto')
        .removeClass('file-empty')
        .append(this.necessaryPaper5);
      $('#remove_necessary_paper_5_button').show();
    }
  }
}
