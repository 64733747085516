// @flow
import JQueryView, { on } from '../../../common/JQueryView';

declare var $: any;

export default class FormView extends JQueryView {
  application: string;
  certificateOfHistory: string;
  certificateOfStamp: string;

  constructor(
    application: string,
    certificateOfHistory: string,
    certificateOfStamp: string,
  ) {
    super('#form_view');

    this.application = application;
    this.certificateOfHistory = certificateOfHistory;
    this.certificateOfStamp = certificateOfStamp;

    $(document).ready(() => {
      this.render();
    });
  }

  @on('change #investor_corporate_document_application')
  onChangeApplication(event: any) {
    if (!event.target.files.length) {
      return;
    }

    const file = $(event.target).prop('files')[0];

    if (file.type === 'application/pdf') {
      this.application = file.name;
      this.render();

      $('#investor_corporate_document_remove_application').val('');
    }
  }

  @on('change #investor_corporate_document_certificate_of_history')
  onChangeCertificateOfHistory(event: any) {
    if (!event.target.files.length) {
      return;
    }

    const file = $(event.target).prop('files')[0];

    if (file.type === 'application/pdf') {
      this.certificateOfHistory = file.name;
      this.render();

      $('#investor_corporate_document_remove_certificate_of_history').val('');
    }
  }

  @on('change #investor_corporate_document_certificate_of_stamp')
  onChangeCertificateOfStamp(event: any) {
    if (!event.target.files.length) {
      return;
    }

    const file = $(event.target).prop('files')[0];

    if (file.type === 'application/pdf') {
      this.certificateOfStamp = file.name;
      this.render();

      $('#investor_corporate_document_remove_certificate_of_stamp').val('');
    }
  }

  @on('click #remove_application_button')
  onClickRemoveApplication() {
    $('#investor_corporate_document_application').val('');
    this.application = '';
    $('#remove_application_button').hide();
    $('#investor_corporate_document_remove_application').val('1');
    this.render();
  }

  @on('click #remove_certificate_of_history_button')
  onClickRemoveCertificateOfHistory() {
    $('#investor_corporate_document_certificate_of_history').val('');
    this.certificateOfHistory = '';
    $('#remove_certificate_of_history_button').hide();
    $('#investor_corporate_document_remove_certificate_of_history').val('1');
    this.render();
  }

  @on('click #remove_certificate_of_stamp_button')
  onClickRemoveCertificateOfStamp() {
    $('#investor_corporate_document_certificate_of_stamp').val('');
    this.certificateOfStamp = '';
    $('#remove_certificate_of_stamp_button').hide();
    $('#investor_corporate_document_remove_certificate_of_stamp').val('1');
    this.render();
  }

  render() {
    if (this.application === '') {
      $('#preview_application')
        .empty()
        .css('height', 'auto')
        .addClass('file-empty')
        .append('ファイルが選択されていません');
      $('#remove_application_button').hide();
    } else {
      $('#preview_application')
        .empty()
        .css('height', 'auto')
        .removeClass('file-empty')
        .append(this.application);
      $('#remove_application_button').show();
    }

    if (this.certificateOfHistory === '') {
      $('#preview_certificate_of_history')
        .empty()
        .css('height', 'auto')
        .addClass('file-empty')
        .append('ファイルが選択されていません');
      $('#remove_certificate_of_history_button').hide();
    } else {
      $('#preview_certificate_of_history')
        .empty()
        .css('height', 'auto')
        .removeClass('file-empty')
        .append(this.certificateOfHistory);
      $('#remove_certificate_of_history_button').show();
    }

    if (this.certificateOfStamp === '') {
      $('#preview_certificate_of_stamp')
        .empty()
        .css('height', 'auto')
        .addClass('file-empty')
        .append('ファイルが選択されていません');
      $('#remove_certificate_of_stamp_button').hide();
    } else {
      $('#preview_certificate_of_stamp')
        .empty()
        .css('height', 'auto')
        .removeClass('file-empty')
        .append(this.certificateOfStamp);
      $('#remove_certificate_of_stamp_button').show();
    }
  }
}
