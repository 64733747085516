// @flow
import React from 'react';
import StringUtil from '../../utils/StringUtil';
import { POPULAR_BANKS_VIEW } from './BankModalView';
import BankClient from '../../models/banks/BankClient';
import type { Bank } from '../../models/banks/Bank';
import type { BankBranch } from '../../models/banks/BankBranch';

type Props = {
  bank: Bank,
  onTransition: (view: number) => void,
  onChangeBranch: (branch: BankBranch) => void,
  onCancel: () => void,
};

type State = {
  branches: Array<BankBranch>,
  appBranches: Array<BankBranch>,
  keyword: string,
};

export default class SelectBranchView extends React.Component<Props, State> {
  bankClient = new BankClient();

  constructor(props: Props) {
    super(props);

    this.state = {
      branches: [],
      appBranches: [],
      keyword: '',
    };
  }

  componentDidMount() {
    this.bankClient
      .fetchBranches(this.props.bank.id)
      .then(response => {
        this.setState({ branches: response.data });
      })
      .catch(error => {
        console.warn(error);
      });
  }

  onChangeKeyword(event: any) {
    const keyword = event.target.value;

    if (keyword !== '') {
      this.setState({
        appBranches: this.state.branches.filter(b => {
          return (
            b.name.indexOf(keyword) !== -1 ||
            b.name.indexOf(StringUtil.convertHalfToFull(keyword)) !== -1 ||
            b.kana.indexOf(StringUtil.convertJaSmallToLarge(keyword)) !== -1 ||
            b.hiragana.indexOf(StringUtil.convertJaSmallToLarge(keyword)) !== -1
          );
        }),
        keyword: keyword,
      });
    } else {
      this.setState({
        appBranches: [],
        keyword: keyword,
      });
    }
  }

  onKeyPress(event: any) {
    if (event.which === 13) {
      event.preventDefault();
    }
  }

  render() {
    return (
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header text-center">
            <h5 className="modal-title w-100">支店を選択</h5>
            <button
              type="button"
              className="close"
              onClick={() => this.props.onCancel()}
            >
              <span>&times;</span>
            </button>
          </div>
          <div className="modal-body p-40">
            <form>
              <div className="form-group">
                <label>支店名で検索</label>
                <input
                  id="bank_branch"
                  type="text"
                  className="form-control"
                  placeholder="支店名を入力"
                  value={this.state.keyword}
                  onChange={e => this.onChangeKeyword(e)}
                  onKeyPress={e => this.onKeyPress(e)}
                />
              </div>
            </form>
            <div className="row mb-30">
              {this.state.appBranches.map(b => {
                return (
                  <div className="col-md-4 mb-20" key={b.id}>
                    <button
                      type="button"
                      className="btn btn-lg btn-block btn-flat btn-outline-primary"
                      onClick={() => this.props.onChangeBranch(b)}
                    >
                      {b.name}
                    </button>
                  </div>
                );
              })}
            </div>
            <button
              type="button"
              className="btn btn-lg btn-block btn-flat btn-outline-primary"
              onClick={() => this.props.onTransition(POPULAR_BANKS_VIEW)}
            >
              戻る
            </button>
          </div>
        </div>
      </div>
    );
  }
}
