// @flow
import JQueryView, { on } from '../../../../common/JQueryView';

declare var $: any;

export default class FormView extends JQueryView {
  constructor() {
    super('.simple_form');

    this.render();
  }

  @on('change input[type="radio"]')
  onChangeRadioButtons() {
    this.render();
  }

  @on('change input[id^="investor_specific_application_legal_personality_"]')
  onChangeLegalPersonality() {
    const qualifiedHolderChecked = $(
      '#investor_specific_application_legal_personality_qualified_holder',
    ).prop('checked');

    if (!qualifiedHolderChecked) {
      $(
        'input[id^="investor_specific_application_has_qualifications_"]:checked',
      ).each((index, element) => {
        $(element).prop('checked', false);
      });
    }
  }

  @on('change input[type="checkbox"]')
  onChangeCheckBoxes() {
    this.render();
  }

  canSubmit(isChecked: boolean) {
    const checkedLength = isChecked ? 7 : 6;

    return (
      checkedLength <=
      ($('input[type="checkbox"]:checked').length > 0 ? 1 : 0) +
        $('input[type="radio"]:checked').length
    );
  }

  render() {
    const qualifiedHolderChecked = $(
      '#investor_specific_application_legal_personality_qualified_holder',
    ).prop('checked');

    if (this.canSubmit(qualifiedHolderChecked)) {
      $('#btn-submit').prop('disabled', false);
    } else {
      $('#btn-submit').prop('disabled', true);
    }

    if (qualifiedHolderChecked) {
      $('.has-qualifications').show();
    } else {
      $('.has-qualifications').hide();
    }
  }
}
