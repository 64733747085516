// @flow
import JQueryView from '../../../common/JQueryView';

declare var $: any;

export default class FormView extends JQueryView {
  constructor() {
    super('#form_view');

    $('.form_date').datetimepicker({
      locale: 'ja',
      format: 'YYYY/MM/DD',
      sideBySide: true,
    });

    $('#company_zip_code').jpostal({
      postcode: ['#company_zip_code'],
      address: {
        '#company_prefecture_id': '%3',
        '#company_city': '%4',
      },
    });
  }
}
