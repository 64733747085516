// @flow
export type Company = {
  id: number,
  name: string,
  thumbUrl: string,
  investorsSize: number,
  lastFinanceOn: string,
  totalFinance: number,
};

export type CompanyProperty = {
  id: number,
  name: string,
  value?: boolean | number,
};

export const fundingBefores: Array<CompanyProperty> = [
  { id: 1, name: '調達あり', value: true },
  { id: 2, name: '調達なし', value: false },
];

export const fundingAfters: Array<CompanyProperty> = [
  { id: 1, name: '調達あり', value: true },
  { id: 2, name: '調達なし', value: false },
];

export const exitExperiences: Array<CompanyProperty> = [
  { id: 1, name: '実績あり', value: true },
  { id: 2, name: '実績なし', value: false },
];

export const fundingSchemes: Array<CompanyProperty> = [
  { id: 1, name: '新株予約権', value: 1 },
  { id: 2, name: '普通株式', value: 0 },
];

export const angelTaxes: Array<CompanyProperty> = [
  { id: 1, name: 'エンジェルA', value: 1 },
  { id: 2, name: 'エンジェルB', value: 2 },
];

export const sdgs: Array<CompanyProperty> = [
  { id: 1, name: '取り組みあり', value: true },
  { id: 2, name: '取り組みなし', value: false },
];

export const ecfFundings: Array<CompanyProperty> = [
  { id: 1, name: '実績あり', value: true },
  { id: 2, name: '実績なし', value: false },
];

export function buildRansackQuery(
  keyword: string,
  fundingBefore: ?number,
  fundingAfter: ?number,
  exitExperience: ?number,
  category: ?number,
  area: ?number,
  fundingScheme: ?number,
  angelTax: ?number,
  sdg: ?number,
  feature: ?number,
  ecfStage: ?number,
  ecfFunding: ?number,
): Object {
  const query: Object = {
    name_cont: keyword,
    show_precedent_true: true,
  };

  let r = fundingBefores.find(item => item.id === fundingBefore);
  if (r != null) {
    query.funding_before_true = r.value;
  }

  r = fundingAfters.find(item => item.id === fundingAfter);
  if (r != null) {
    query.funding_after_true = r.value;
  }

  r = fundingAfters.find(item => item.id === exitExperience);
  if (r != null) {
    query.exit_experience_true = r.value;
  }

  if (category != null) {
    query.projects_categories_id_in = category;
  }

  if (area != null) {
    query.areas_id_in = area;
  }

  r = fundingSchemes.find(item => item.id === fundingScheme);
  if (r != null) {
    query.projects_stock_type_eq = r.value;
    if (
      !Object.prototype.hasOwnProperty.call(query, 'projects_deliv_at_not_null')
    ) {
      query.projects_deliv_at_not_null = true;
    }
  }

  r = angelTaxes.find(item => item.id === angelTax);
  if (r != null) {
    query.projects_angel_type_eq = r.value;
    if (
      !Object.prototype.hasOwnProperty.call(query, 'projects_deliv_at_not_null')
    ) {
      query.projects_deliv_at_not_null = true;
    }
  }

  r = sdgs.find(item => item.id === sdg);
  if (r != null) {
    query.sdgs_true = r.value;
  }

  if (feature != null) {
    query.features_id_in = feature;
  }

  if (ecfStage != null) {
    query.ecf_stages_id_in = ecfStage;
  }

  r = ecfFundings.find(item => item.id === ecfFunding);
  if (r != null) {
    query.ecf_funding_true = r.value;
  }

  return query;
}
