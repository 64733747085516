// @flow
import type { Condition } from './Condition';
import { validate as validateCondition } from './Condition';

export type Kycc = {
  id: ?number,
  companyId: ?number,
  conditions: Array<Condition>,
  kyccMonthlyLimit: number,
  kyccCheckedCount: number,
  errors: any,
};

export type Customer = Kycc & {};
export type CustomerOversea = Kycc & {};
export type Company = Kycc & {};
export type CompanyOversea = Kycc & {};

export const Target = {
  domestic: 0,
  oversea: 1,
};

export function validate(kycc: Customer | Company): boolean {
  let errorsCount = 0;

  kycc.errors = {};
  if (kycc.conditions.length > 0) {
    kycc.conditions.forEach(kycc => {
      if (!validateCondition(kycc)) {
        errorsCount += Object.keys(kycc.errors).length;
      }
    });
  } else {
    kycc.errors['base'] = ['対象を入力してください'];
    errorsCount++;
  }

  return errorsCount == 0;
}

export function hasError(kycc: Kycc, field: string): boolean {
  return kycc.errors[field] != null && kycc.errors[field].length > 0;
}
