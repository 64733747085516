// @flow
import JQueryView, { on } from '../../common/JQueryView';

declare var $: any;

export default class PreView extends JQueryView {
  selectedKycTypeId: string;

  constructor() {
    super('.show');
    this.selectedKycTypeId = '';
    this.render();
  }

  @on('change .kyc-type')
  onChangeKycType(ele: Object) {
    this.selectedKycTypeId = ele.target.id;
    this.render();
  }

  render() {
    $('.submission').removeClass('selected');
    if (this.selectedKycTypeId === '') {
      $('#kyc_type_submit').prop('disabled', true);
    } else {
      $(`label[for="${this.selectedKycTypeId}"]`).addClass('selected');
      $('#kyc_type_submit').prop('disabled', false);
    }
  }
}
