// @flow
import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import * as linkHeaderParser from 'linkheader-parser/dist/linkheader-parser-node';
import ProjectClient from '../../models/projects/ProjectClient';
import Loader from '../common/Loader';
import MobileSelector from './MobileSelector';
import ProjectsSelector from './ProjectsSelector';
import Card from './Card';
import type { Project } from '../../models/projects/Project';

type Props = {
  keyword: ?string,
  order: ?string,
  mobile: boolean,
  spefic_investor_flg: boolean,
};

type State = {
  projects: Array<Project>,
  keyword: string,
  order: ?string,
  isLoading: boolean,
  page: number,
  hasNextPage: boolean,
  typingTimeout: any,
};

export default class IndexView extends React.Component<Props, State> {
  projectClient = new ProjectClient();

  constructor(props: Props) {
    super(props);

    this.state = {
      projects: [],
      keyword: props.keyword != null ? props.keyword : '',
      order: props.order,
      spefic_investor_flg: props.spefic_investor_flg,
      isLoading: true,
      page: 1,
      hasNextPage: true,
      typingTimeout: 0,
    };
  }

  componentDidMount() {
    this.fetchProjects();
  }

  fetchProjects() {
    const { page, keyword, order } = this.state;

    this.projectClient
      .fetchAll(page, keyword === '' ? null : keyword, order)
      .then(response => {
        const hasNextPage =
          response.headers &&
          response.headers.hasOwnProperty('link') &&
          linkHeaderParser.parse(response.headers.link).hasOwnProperty('next');
        this.setState({
          projects: [...this.state.projects, ...response.data],
          hasNextPage: hasNextPage,
          isLoading: false,
        });
      })
      .catch(error => {
        console.warn(error);
      });
  }

  onLoadMore() {
    if (!this.state.isLoading && this.state.hasNextPage) {
      this.setState(
        {
          page: this.state.page + 1,
          isLoading: true,
        },
        () => this.fetchProjects(),
      );
    }
  }

  onChangeKeyword(event: any) {
    this.setState({ keyword: event.target.value });
  }

  onClickKeywordSearch() {
    if (this.state.typingTimeout > 0) {
      clearTimeout(this.state.typingTimeout);
    }

    this.setState({
      projects: [],
      page: 1,
      isLoading: true,
      hasNextPage: true,
      order: null,
      typingTimeout: setTimeout(() => {
        this.fetchProjects();
        // history.pushState(null, '', `/projects?keyword=${keyword}`);
      }, 1000),
    });
  }

  onSubmitSearch(event: any) {
    event.preventDefault();

    this.onClickKeywordSearch();
  }

  onClickOrder(order: ?string) {
    if (!this.state.isLoading) {
      this.setState(
        {
          projects: [],
          page: 1,
          isLoading: true,
          hasNextPage: true,
          keyword: '',
          order: order,
        },
        () => this.fetchProjects(),
      );
    }
  }

  render() {
    const { spefic_investor_flg } = this.props;
    const { order, keyword, isLoading, projects, hasNextPage } = this.state;

    return (
      <div>
        <div className="row">
          <div className="col d-block d-lg-none mb-10 justify-content-center">
            <MobileSelector
              order={order}
              keyword={keyword}
              onChangeKeyword={e => this.onChangeKeyword(e)}
              onClickOrder={order => this.onClickOrder(order)}
              onClickKeywordSearch={() => this.onClickKeywordSearch()}
            />
          </div>
          <div className="col-lg-3 d-none d-lg-block">
            <ProjectsSelector
              order={order}
              keyword={keyword}
              onChangeKeyword={e => this.onChangeKeyword(e)}
              onClickOrder={order => this.onClickOrder(order)}
              onClickKeywordSearch={() => this.onClickKeywordSearch()}
              onSubmitSearch={e => this.onSubmitSearch(e)}
            />
          </div>
          <div className="col-lg-9 main-row__wrapper">
            <InfiniteScroll
              pageStart={0}
              loadMore={() => this.onLoadMore()}
              hasMore={hasNextPage}
              loader={<Loader key={0} type="water" />}
              className="row"
            >
              {projects.map(project => (
                <Card
                  key={project.id}
                  project={project}
                  spefic_investor_flg={spefic_investor_flg}
                />
              ))}
            </InfiniteScroll>
            {!isLoading && projects.length === 0 && (
              <div>プロジェクトは見つかりませんでした</div>
            )}
          </div>
          <div className="col d-block d-lg-none">
            <div className="project-selector__attention">
              <span>※</span>
              <span>
                募集ページについては、募集期間終了後、募集の成立不成立に関わらず事業者の要請に応じて非開示となることがあります。ご了承下さい。
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
