// @flow
import React from 'react';
import BankModalView from '../../banks/BankModalView';
import type { Bank } from '../../../models/banks/Bank';
import type { BankBranch } from '../../../models/banks/BankBranch';

declare var $: any;

type Props = {
  bank: ?Bank,
  branch: ?BankBranch,
  errors: any,
};

type State = {
  bank: ?Bank,
  branch: ?BankBranch,
};

export default class BankView extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      bank: props.bank,
      branch: props.branch,
    };
  }

  onOk() {
    $('#bank_modal').modal('hide');
  }

  onCancel() {
    this.setState({
      bank: null,
      branch: null,
    });
    $('#bank_modal').modal('hide');
  }

  hasError() {
    const { errors } = this.props;
    return (
      (errors.bank_id != null && errors.bank_id.length > 0) ||
      (errors.bank_branch_id != null && errors.bank_branch_id.length > 0)
    );
  }

  renderError() {
    return this.hasError() ? (
      <span className="help-block text-danger">を選択してください</span>
    ) : null;
  }

  render() {
    const { bank, branch } = this.state;

    return (
      <div>
        <div
          className={
            this.hasError() ? 'form-group row has-error' : 'form-group row'
          }
        >
          <label className="col-sm-3 col-form-label">
            金融機関・支店 <span className="required-mark">＊</span>
          </label>
          <div className="col-sm-9">
            <div className="row">
              <div className="col-sm-8">
                {(bank != null || branch != null) && (
                  <div>
                    {bank != null && <span className="mr-10">{bank.name}</span>}
                    {branch != null && (
                      <span className="mr-10">{branch.name}</span>
                    )}
                  </div>
                )}
                <button
                  type="button"
                  className="btn btn-lg btn-flat btn-primary-outline-gray"
                  data-toggle="modal"
                  data-target="#bank_modal"
                >
                  金融機関・支店を選択
                </button>
                {this.renderError()}
                <input
                  value={bank != null ? bank.id : ''}
                  type="hidden"
                  name="user[bank_id]"
                />
                <input
                  value={branch != null ? branch.id : ''}
                  type="hidden"
                  name="user[bank_branch_id]"
                />
              </div>
            </div>
          </div>
        </div>

        <BankModalView
          bank={bank}
          branch={branch}
          onChangeBank={bank => this.setState({ bank: bank })}
          onChangeBranch={branch => this.setState({ branch: branch })}
          onOk={() => this.onOk()}
          onCancel={() => this.onCancel()}
        />
      </div>
    );
  }
}
