// @flow
import React from 'react';
import moment from 'moment';
import type { Project } from '../../models/projects/Project';
import { STATUS, EDITOR } from '../../models/projects/Project';
import { stripTags } from '../../helpers/ApplicationHelper';

type Props = {
  project: Project,
};

export default function CardDescription(props: Props) {
  const { project } = props;

  if (project.status === STATUS.failed) {
    if (project.withdraw_reason) {
      return (
        <div className="description">{stripTags(project.withdraw_reason)}</div>
      );
    } else if (project.execution_at) {
      if (project.execution_at < project.failed_at) {
        return (
          <div className="description">
            このプロジェクトは、目標募集金額を上回って募集が終了し、約定いたしましたが、その後、一部の投資家様からの払い込みがなかったため、失権により目標募集額を下回ってしまいました。
            発行者と協議の結果、発行者の判断により募集を中止させていただきました。
            ご応募・ご検討いただいた投資家の皆様には誠に申し訳ございません。
            なお、すでに払い込みいただいた投資家様には別途メールにてご連絡させていただいておりますのでそちらもご確認くださいますようよろしくお願いいたします。
          </div>
        );
      } else {
        return (
          <div className="description">
            このプロジェクトは
            {moment(project.finish_at).format('YYYY年MM月DD日')}
            をもちまして募集を終了いたしましたのでお申込みいただく事はできません。
            なお、このプロジェクトは目標募集額を上回って終了したものの、キャンセル可能期間中において目標募集額を下回ったために不成立となり、お申込みいただいたご注文は取り消されました。
            応募金額は募集期間終了時点のものです。
          </div>
        );
      }
    } else {
      if (project.is_withdraw) {
        if (project.id === 154) {
          return (
            <div className="description">
              このプロジェクトは、発行者より募集の延期要請があり、発行者と協議の結果、当初予定していた
              {moment(project.start_at).format('YYYY年MM月DD日')}
              からの募集を一旦取り下げさせていただきました。
              ご検討いただいた投資家の皆様には誠に申し訳ございません、今後募集を行う場合には、投資家の皆様にあらためてご連絡させていただきます。
            </div>
          );
        } else if (project.failed_at && project.start_at < project.failed_at) {
          return (
            <div className="description">
              このプロジェクトは、ここ最近の諸事情を鑑み、発行者の要請により、
              {moment(project.failed_at).format('YYYY年MM月DD日')}
              をもちまして募集の取扱いを取り下げられましたのでお申込みいただくことができません。
              <br />
              なお、応募金額は取り下げた時点における金額です。
            </div>
          );
        } else {
          return (
            <div className="description">
              {moment(project.start_at).format('YYYY年MM月DD日')}
              よりお申込み開始を予定しておりましたこのプロジェクトは、ここ最近の諸事情を鑑み、発行者の要請により、
              {moment(project.failed_at).format('YYYY年MM月DD日')}
              をもちまして、募集の取扱いが取り下げられました。
            </div>
          );
        }
      } else {
        return (
          <div className="description">
            このプロジェクトは
            {moment(project.finish_at).format('YYYY年MM月DD日')}
            をもちまして募集を終了いたしましたのでお申込みいただく事はできません。
            なお、このプロジェクトは募集期間終了日において目標募集額を下回ったため不成立となり、お申込みいただいたご注文は取り消されました。
          </div>
        );
      }
    }
  } else if (
    moment(project.start_at).isAfter(moment()) ||
    project.products_size === 0
  ) {
    return (
      <div className="description">
        このプロジェクトは現在情報の開示をおこなっておりますが、お申込みいただくことはできません。お申込みの開始は
        {moment(project.start_at).format('YYYY年MM月DD日 HH:mm')}
        の予定です。
      </div>
    );
  } else {
    if (project.editor_version === EDITOR.normal) {
      return (
        <div className="description">
          {stripTags(project.short_description)}
        </div>
      );
    } else if (project.keywords.length != 0) {
      return (
        <div className="c-projectItem__content">
          <div className="c-projectItem__tags l-flexbox">
            {project.keywords
              .sort((a, b) => a.display_order - b.display_order)
              .slice(0, 4)
              .map(k => (
                <span key={k.display_order}> {k.name}</span>
              ))}
          </div>
        </div>
      );
    } else {
      return (
        <div className="blank">
          <span>　</span>
        </div>
      );
    }
  }
}
