// @flow
import JQueryView, { on } from '../../../common/JQueryView';

declare var $: any;

export default class IndexView extends JQueryView {
  constructor() {
    super('#index_view');
    this.render();
  }

  @on('click .dropdown-menu')
  onClickDropdownMenu(event: any) {
    $(event.stopPropagation());
  }

  @on('click .selection')
  onClickSelection() {
    this.render();
  }

  render() {
    let statuses = '';
    $('input[name="q[status_eq_any][]"]').each((index, element) => {
      if ($(element).prop('checked') === true) {
        statuses += $(element)
          .parent('label')
          .text();
        statuses += ',';
      }
    });
    if (statuses !== '') {
      $('#drop_link').text(statuses.replace(/\s/g, '').slice(0, -1));
    } else {
      $('#drop_link').text('ステータスで絞り込み');
    }
  }
}
