// @flow
import JQueryView, { on } from '../../../../common/JQueryView';

declare var $: any;

export default class FileUploadView extends JQueryView {
  constructor() {
    super('.simple_form');

    this.render();
  }

  @on('blur #investor_specific_application_assets')
  onBlurAssets() {
    this.render();
  }

  @on('blur #investor_specific_application_liabilities')
  onBlurLiabilities() {
    this.render();
  }

  @on(
    'change #investor_specific_application_check_box_income_paper_work_company_name_vary_reason',
  )
  onChangeCheckBoxIncomePaperReason() {
    this.render();
  }

  @on(
    'change #investor_specific_application_income_paper_work_company_name_vary_reason',
  )
  onChangeIncomePaperReason() {
    this.render();
  }

  @on(
    'change #investor_specific_application_check_box_specific_knowledge_paper_work_company_name_vary_reason',
  )
  onChangeCheckBoxSpecificKnowledgeReason() {
    this.render();
  }

  @on(
    'change #investor_specific_application_specific_knowledge_paper_work_company_name_vary_reason',
  )
  onChangeSpecificKnowledgeReason() {
    this.render();
  }

  @on('change #investor_specific_application_income_papers')
  onChangeIncomePapers() {
    this.render();
  }

  @on('change #investor_specific_application_specific_knowledge_papers')
  onChangeSpecificKnowledgePapers() {
    this.render();
  }

  @on('change #investor_specific_application_investible_financial_asset_papers')
  onChangeInvestibleFinancialAssetPapers() {
    this.render();
  }

  @on('change #investor_specific_application_exp_stock_papers')
  onChangeExpStockPapers() {
    this.render();
  }

  @on('change #investor_specific_application_other_papers')
  onChangeOtherPapers() {
    this.render();
  }

  canSubmit() {
    const pattern = $('#investor_specific_application_pattern').val();

    switch (pattern) {
      case 'pattern_1':
      case 'pattern_2':
      case 'pattern_4':
        return this.canSubmitPatternA();
      case 'pattern_3':
        return this.canSubmitPatternB();
      case 'pattern_5':
      case 'pattern_6':
      case 'pattern_8':
        return this.canSubmitPatternC();
      case 'pattern_7':
        return this.canSubmitPatternD();
      case 'pattern_9':
      case 'pattern_10':
      case 'pattern_12':
        return this.canSubmitPatternE();
      case 'pattern_11':
        return this.canSubmitPatternF();
      case 'pattern_13':
        return this.canSubmitPatternG();
      case 'pattern_14':
      case 'pattern_16':
      case 'pattern_17':
        return this.canSubmitPatternH();
      case 'pattern_15':
      case 'pattern_18':
        return this.canSubmitPatternI();
      default:
        break;
    }
  }

  canSubmitPatternA() {
    return (
      this.checkIncomePapers() &&
      this.checkSpecificKnowledgePapers() &&
      this.checkIncomePaperReason() &&
      this.checkSpecificKnowledgeReason() &&
      this.checkInvestmentExperiencePapers() &&
      this.checkOtherPapers()
    );
  }

  canSubmitPatternB() {
    return (
      this.checkIncomePapers() &&
      this.checkSpecificKnowledgePapers() &&
      this.checkIncomePaperReason() &&
      this.checkInvestmentExperiencePapers() &&
      this.checkOtherPapers()
    );
  }

  canSubmitPatternC() {
    return (
      this.checkInvestibleFinancialAssetPapers() &&
      this.checkSpecificKnowledgePapers() &&
      this.checkNetAssets() &&
      this.checkSpecificKnowledgeReason() &&
      this.checkInvestmentExperiencePapers() &&
      this.checkOtherPapers()
    );
  }

  canSubmitPatternD() {
    return (
      this.checkInvestibleFinancialAssetPapers() &&
      this.checkSpecificKnowledgePapers() &&
      this.checkNetAssets() &&
      this.checkInvestmentExperiencePapers() &&
      this.checkOtherPapers()
    );
  }

  canSubmitPatternE() {
    return (
      this.checkInvestibleFinancialAssetPapers() &&
      this.checkSpecificKnowledgePapers() &&
      this.checkSpecificKnowledgeReason() &&
      this.checkInvestmentExperiencePapers() &&
      this.checkOtherPapers()
    );
  }

  canSubmitPatternF() {
    return (
      this.checkInvestibleFinancialAssetPapers() &&
      this.checkSpecificKnowledgePapers() &&
      this.checkInvestmentExperiencePapers() &&
      this.checkOtherPapers()
    );
  }

  canSubmitPatternG() {
    return (
      this.checkIncomePapers() &&
      this.checkIncomePaperReason() &&
      this.checkInvestmentExperiencePapers() &&
      this.checkOtherPapers()
    );
  }

  canSubmitPatternH() {
    return (
      this.checkInvestibleFinancialAssetPapers() &&
      this.checkNetAssets() &&
      this.checkInvestmentExperiencePapers() &&
      this.checkOtherPapers()
    );
  }

  canSubmitPatternI() {
    return (
      this.checkInvestibleFinancialAssetPapers() &&
      this.checkInvestmentExperiencePapers() &&
      this.checkOtherPapers()
    );
  }

  checkIncomePapers() {
    const incomePapers = $('#investor_specific_application_income_papers');

    return 0 < incomePapers.val().length;
  }

  checkSpecificKnowledgePapers() {
    const specificKnowledgePapers = $(
      '#investor_specific_application_specific_knowledge_papers',
    );

    return 0 < specificKnowledgePapers.val().length;
  }

  checkInvestibleFinancialAssetPapers() {
    const investibleFinancialAssetPapers = $(
      '#investor_specific_application_investible_financial_asset_papers',
    );

    return 0 < investibleFinancialAssetPapers.val().length;
  }

  checkIncomePaperReason() {
    const checkBoxIncomePaperReason = $(
      '#investor_specific_application_check_box_income_paper_work_company_name_vary_reason',
    );
    const incomePaperReasonLength = $(
      '#investor_specific_application_income_paper_work_company_name_vary_reason',
    ).val().length;

    if (
      checkBoxIncomePaperReason.prop('checked') &&
      incomePaperReasonLength === 0
    ) {
      return false;
    }

    return true;
  }

  checkSpecificKnowledgeReason() {
    const checkBoxSpecificKnowledgeReason = $(
      '#investor_specific_application_check_box_specific_knowledge_paper_work_company_name_vary_reason',
    );
    const specificKnowledgeReasonLength = $(
      '#investor_specific_application_specific_knowledge_paper_work_company_name_vary_reason',
    ).val().length;

    if (
      checkBoxSpecificKnowledgeReason.prop('checked') &&
      specificKnowledgeReasonLength === 0
    ) {
      return false;
    }

    return true;
  }

  checkNetAssets() {
    const assets = $('#investor_specific_application_assets');
    const liabilities = $('#investor_specific_application_liabilities');
    const pattern = $('#investor_specific_application_pattern').val();
    let minNetAssets = 0;

    switch (pattern) {
      case 'pattern_5':
      case 'pattern_6':
      case 'pattern_7':
      case 'pattern_8':
        minNetAssets = 100;
        break;
      case 'pattern_14':
      case 'pattern_16':
        minNetAssets = 300;
        break;
      case 'pattern_17':
        minNetAssets = 500;
        break;
      default:
        break;
    }

    return (
      0 < assets.val().length &&
      0 <= assets.val() &&
      0 < liabilities.val().length &&
      0 <= liabilities.val() &&
      minNetAssets <= assets.val() - liabilities.val()
    );
  }

  checkInvestmentExperiencePapers() {
    const expStockPapers = $('#investor_specific_application_exp_stock_papers');

    if ($('#exp-stock-papers-required').get(0)) {
      return 0 < expStockPapers.val().length;
    } else {
      return true;
    }
  }

  checkOtherPapers() {
    const otherPapers = $('#investor_specific_application_other_papers');
    const pattern = $('#investor_specific_application_pattern').val();

    if ($('#other-papers-required').get(0)) {
      switch (pattern) {
        case 'pattern_4':
        case 'pattern_8':
        case 'pattern_12':
          return true;
        default:
          return 0 < otherPapers.val().length;
      }
    } else {
      return true;
    }
  }

  render() {
    const specificKnowledgeReason = $(
      '#investor_specific_application_check_box_specific_knowledge_paper_work_company_name_vary_reason',
    )[0];
    const incomePaperReason = $(
      '#investor_specific_application_check_box_income_paper_work_company_name_vary_reason',
    )[0];
    const assets = $('#investor_specific_application_assets')[0] || 0;
    const liabilities = $('#investor_specific_application_liabilities')[0] || 0;

    if (specificKnowledgeReason && specificKnowledgeReason.checked) {
      $('.specific-knowledge-paper-work-company-name-vary-reason').show();
    } else {
      $(
        '#investor_specific_application_specific_knowledge_paper_work_company_name_vary_reason',
      ).val('');
      $('.specific-knowledge-paper-work-company-name-vary-reason').hide();
    }

    if (incomePaperReason && incomePaperReason.checked) {
      $('.income-paper-work-company-name-vary-reason').show();
    } else {
      $(
        '#investor_specific_application_income_paper_work_company_name_vary_reason',
      ).val('');
      $('.income-paper-work-company-name-vary-reason').hide();
    }

    if (assets && liabilities) {
      $('#investor_specific_application_net_assets').val(
        assets.value - liabilities.value,
      );
    }

    if (this.canSubmit()) {
      $('#btn-submit').prop('disabled', false);
    } else {
      $('#btn-submit').prop('disabled', true);
    }
  }
}
