// @flow
import JQueryView, { on } from '../../../../common/JQueryView';

declare var $: any;

export default class FileUploadView extends JQueryView {
  constructor() {
    super('.simple_form');

    this.render();
  }

  @on('blur #investor_specific_application_assets')
  onBlurAssets() {
    this.render();
  }

  @on('blur #investor_specific_application_liabilities')
  onBlurLiabilities() {
    this.render();
  }

  @on('change #investor_specific_application_settlement_papers')
  onChangeSettlementPapers() {
    this.render();
  }

  @on('change #investor_specific_application_investible_financial_asset_papers')
  onChangeInvestibleFinancialAssetPapers() {
    this.render();
  }

  @on('change #investor_specific_application_articles_of_incorporations')
  onChangeArticlesOfIncorporations() {
    this.render();
  }

  @on('change #investor_specific_application_specific_knowledge_papers')
  onChangeSpecificKnowledgePapers() {
    this.render();
  }

  @on('change #investor_specific_application_other_qualifications')
  onChangeOtherQualifications() {
    this.render();
  }

  @on(
    'change #investor_specific_application_other_investment_initiatives_papers',
  )
  onChangeOtherInvestmentInitiativesPapers() {
    this.render();
  }

  @on('change #investor_specific_application_other_investment_initiatives')
  onChangeOtherInvestmentInitiatives() {
    this.render();
  }

  canSubmit() {
    const pattern = $('#investor_specific_application_pattern').val();

    switch (pattern) {
      case 'pattern_1':
        return this.canSubmitPatternA();
      case 'pattern_2':
      case 'pattern_4':
        return this.canSubmitPatternB();
      case 'pattern_3':
        return this.canSubmitPatternC();
      case 'pattern_16':
      case 'pattern_17':
        return this.canSubmitPatternD();
      case 'pattern_18':
      case 'pattern_19':
      case 'pattern_20':
      case 'pattern_21':
      case 'pattern_22':
      case 'pattern_23':
        return this.canSubmitPatternE();
      case 'pattern_24':
        return this.canSubmitPatternF();
      case 'pattern_25':
        return this.canSubmitPatternG();
      default:
        break;
    }
  }

  canSubmitPatternA() {
    return this.checkSettlementPapers();
  }

  canSubmitPatternB() {
    return this.checkInvestibleFinancialAssetPapers();
  }

  canSubmitPatternC() {
    return this.checkInvestibleFinancialAssetPapers() && this.checkNetAssets();
  }

  canSubmitPatternD() {
    return this.checkArticlesOfIncorporations();
  }

  canSubmitPatternE() {
    return this.checkSpecificKnowledgePapers();
  }

  canSubmitPatternF() {
    return (
      this.checkSpecificKnowledgePapers() && this.checkOtherQualifications()
    );
  }

  canSubmitPatternG() {
    return (
      this.checkOtherInvestmentInitiativesPapers() &&
      this.checkotherInvestmentInitiatives()
    );
  }

  checkSettlementPapers() {
    const settlementPapers = $(
      '#investor_specific_application_settlement_papers',
    );

    return 0 < settlementPapers.val().length;
  }

  checkInvestibleFinancialAssetPapers() {
    const investibleFinancialAssetPapers = $(
      '#investor_specific_application_investible_financial_asset_papers',
    );

    return 0 < investibleFinancialAssetPapers.val().length;
  }

  checkNetAssets() {
    const assets = $('#investor_specific_application_assets');
    const liabilities = $('#investor_specific_application_liabilities');
    const minNetAssets = 100;

    return (
      0 < assets.val().length &&
      0 <= assets.val() &&
      0 < liabilities.val().length &&
      0 <= liabilities.val() &&
      minNetAssets <= assets.val() - liabilities.val()
    );
  }

  checkArticlesOfIncorporations() {
    const articlesOfIncorporations = $(
      '#investor_specific_application_articles_of_incorporations',
    );

    return 0 < articlesOfIncorporations.val().length;
  }

  checkSpecificKnowledgePapers() {
    const specificKnowledgePapers = $(
      '#investor_specific_application_specific_knowledge_papers',
    );

    return 0 < specificKnowledgePapers.val().length;
  }

  checkOtherQualifications() {
    const otherQualification = $(
      '#investor_specific_application_other_qualifications',
    );

    return 0 < otherQualification.val().length;
  }

  checkOtherInvestmentInitiativesPapers() {
    const otherInvestmentInitiativesPapers = $(
      '#investor_specific_application_other_investment_initiatives_papers',
    );

    return 0 < otherInvestmentInitiativesPapers.val().length;
  }

  checkotherInvestmentInitiatives() {
    const investmentInitiatives = $(
      '#investor_specific_application_other_investment_initiatives',
    );

    return 0 < investmentInitiatives.val().length;
  }

  render() {
    const assets = $('#investor_specific_application_assets')[0] || 0;
    const liabilities = $('#investor_specific_application_liabilities')[0] || 0;

    if (assets && liabilities) {
      $('#investor_specific_application_net_assets').val(
        assets.value - liabilities.value,
      );
    }

    if (this.canSubmit()) {
      $('#btn-submit').prop('disabled', false);
    } else {
      $('#btn-submit').prop('disabled', true);
    }
  }
}
