// @flow
import React from 'react';
import 'moment/locale/ja';
import type { Project } from '../../models/projects/Project';
import { STATUS } from '../../models/projects/Project';

type Props = {
  project: Project,
};

export default function CardRibbon(props: Props) {
  const { project } = props;

  if (project.status === STATUS.executed) {
    return (
      <div className="ribbon executed">
        <div className="title">成約済み</div>
      </div>
    );
  }

  return null;
}
