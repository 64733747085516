// @flow
import Qs from 'qs';
import AxiosClient from '../AxiosClient';
import type { AxiosPromise } from 'axios';
import type { Company } from './Company';

export class CompanyClient extends AxiosClient {
  searchCompanies(page: number, query: Object): AxiosPromise<Array<Company>> {
    return this.buildCamelCaseClient().get('/api/v1/companies/search.json', {
      params: {
        page: page,
        q: query,
      },
      paramsSerializer: params =>
        Qs.stringify(params, { arrayFormat: 'brackets' }),
    });
  }
}
