// @flow
import moment from 'moment';
import AxiosClient from '../../AxiosClient';
import type { AxiosPromise } from 'axios';
import type {
  Customer,
  CustomerOversea,
  Company,
  CompanyOversea,
} from './Kycc';
import { Target } from './Kycc';

export class KyccClient extends AxiosClient {
  ckeckCustomer(
    target: number,
    customer: Customer | CustomerOversea,
  ): AxiosPromise<Customer | CustomerOversea> {
    const data = new FormData();

    customer.conditions.forEach((condition, i) => {
      if (condition.name != null) {
        data.append(
          `company_kycc_customer${
            target === Target.oversea ? '_oversea' : ''
          }[conditions_attributes][${i}][name]`,
          condition.name,
        );
      }

      if (condition.birthday != null) {
        data.append(
          `company_kycc_customer${
            target === Target.oversea ? '_oversea' : ''
          }[conditions_attributes][${i}][birthday]`,
          moment(condition.birthday).format('YYYY-MM-DD'),
        );
      }
    });

    return this.buildCamelCaseClient().post(
      `/api/v1/my/company/kycc/customer${
        target === Target.oversea ? '_oversea' : ''
      }.json`,
      data,
    );
  }

  ckeckCompany(
    target: number,
    company: Company | CompanyOversea,
  ): AxiosPromise<Company | CompanyOversea> {
    const data = new FormData();
    company.conditions.forEach((condition, i) => {
      if (condition.name != null) {
        data.append(
          `company_kycc_company${
            target === Target.oversea ? '_oversea' : ''
          }[conditions_attributes][${i}][name]`,
          condition.name,
        );
      }
    });

    return this.buildCamelCaseClient().post(
      `/api/v1/my/company/kycc/company${
        target === Target.oversea ? '_oversea' : ''
      }.json`,
      data,
    );
  }
}
