// @flow
import React from 'react';
import { SELECT_BANK_VIEW } from './BankModalView';
import type { Bank } from '../../models/banks/Bank';

type Props = {
  banks: Array<Bank>,
  onTransition: (view: number) => void,
  onChangeBank: (bank: Bank) => void,
  onCancel: () => void,
};

export default class PopularBanksView extends React.Component<Props> {
  render() {
    return (
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header text-center">
            <h5 className="modal-title w-100">金融機関を選択</h5>
            <button
              type="button"
              className="close"
              onClick={() => this.props.onCancel()}
            >
              <span>&times;</span>
            </button>
          </div>
          <div className="modal-body p-40">
            <div className="row mb-30">
              {this.props.banks
                .filter(b => b.is_popular)
                .map(b => {
                  return (
                    <div className="col-md-4 mb-20" key={b.id}>
                      <button
                        type="button"
                        className="btn btn-lg btn-block btn-flat btn-outline-primary"
                        onClick={() => this.props.onChangeBank(b)}
                      >
                        {b.name}
                      </button>
                    </div>
                  );
                })}
            </div>
            <button
              type="button"
              className="btn btn-lg btn-block btn-flat btn-outline-primary"
              onClick={() => this.props.onTransition(SELECT_BANK_VIEW)}
            >
              ご利用の銀行がこの中にない場合
            </button>
          </div>
        </div>
      </div>
    );
  }
}
