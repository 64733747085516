// @flow
import React from 'react';
import { Button, Col, FormFeedback, Input, Row } from 'reactstrap';
import type { Condition } from '../../../../../models/companies/kyccs/Condition';
import { hasError } from '../../../../../models/companies/kyccs/Condition';

type Props = {
  index: number,
  condition: Condition,
  onChange: Condition => void,
  onRemove: () => void,
};

export function ConditionInput({
  index,
  condition,
  onChange,
  onRemove,
}: Props) {
  return (
    <Row form className="condition-input mb-3">
      <Col md={11}>
        <Input
          type="text"
          id={`condition_name_${index}`}
          value={condition.name != null ? condition.name : ''}
          placeholder="法人名"
          invalid={hasError(condition, 'name')}
          onChange={e => onChange({ ...condition, name: e.target.value })}
        />
        {hasError(condition, 'name') && (
          <FormFeedback>{condition.errors['name'][0]}</FormFeedback>
        )}
      </Col>
      <Col md={1}>
        <Button color="link" onClick={onRemove}>
          <i className="far fa-times-circle"></i>
        </Button>
      </Col>
    </Row>
  );
}
