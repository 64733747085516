// @flow
import * as axios from 'axios';
import { mapKeys, camelCase, split } from 'lodash';
import { deepMapKeys } from '../utils/commonUtils';

export default class AxiosClient {
  buildClient(config: Object = {}): any {
    return axios.create({
      ...config,
      timeout: 60000,
    });
  }

  buildCamelCaseClient(config: Object = {}): any {
    const axios = this.buildClient(config);
    axios.interceptors.response.use(res => {
      if (res.data) res.data = deepMapKeys(res.data, key => camelCase(key));
      return res;
    });
    return axios;
  }

  _camelCaseErrorResponse(error: Object) {
    if (error.details) {
      error.details = mapKeys(error.details, (value, key) =>
        camelCase(split(key, '.').pop()),
      );
    }
    return mapKeys(error, (value, key) => camelCase(key));
  }
}
