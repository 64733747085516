// @flow
import moment from 'moment';

export function stripTags(str: ?string): string {
  if (str == null) {
    return '';
  } else {
    return str.replace(/<("[^"]*"|'[^']*'|[^'">])*>/g, '');
  }
}

export function date(date: ?string): string {
  return date != null ? moment(date).format('YYYY年MM月DD日') : '';
}

export function datetime(datetime: ?string): string {
  return datetime != null
    ? moment(datetime).format('YYYY年MM月DD日 HH:SS')
    : '';
}

export function delimited(n: ?number): string {
  if (n == null) {
    return '';
  }

  return String(n).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
}

export function filename(path: ?string): string {
  if (path == null) {
    return '';
  }

  const arr = path.match('.+/(.+?)([#;].*)?$');
  if (arr == null) {
    return '';
  }

  return arr[1];
}

export function browserName(): string {
  const userAgent = window.navigator.userAgent.toLowerCase();

  if (userAgent.indexOf('msie') !== -1) {
    return 'ie';
  } else if (userAgent.indexOf('chrome') !== -1) {
    return 'chrome';
  } else if (userAgent.indexOf('firefox') !== -1) {
    return 'firefox';
  } else if (userAgent.indexOf('safari') !== -1) {
    return 'safari';
  } else if (userAgent.indexOf('opera') !== -1) {
    return 'opera';
  } else if (userAgent.indexOf('gecko') !== -1) {
    return 'gecko';
  } else {
    return '';
  }
}
