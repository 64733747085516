// @flow
import JQueryView, { on } from '../../common/JQueryView';

export default class FormView extends JQueryView {
  others: boolean;

  constructor(others: boolean) {
    super('.simple_form');
    this.others = others;
    this.render();
  }

  @on('change #white_paper_user_role')
  onChangeOther() {
    var roleType = $('#white_paper_user_role').val();
    if (roleType == 'other') {
      this.others = true;
      this.render();
    } else {
      this.others = false;
      this.render();
    }
  }

  render() {
    if (this.others) {
      $('#white_paper_user_other_role').show();
    } else {
      $('#white_paper_user_other_role').hide();
    }
  }
}
