// @flow
import React from 'react';
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from 'reactstrap';
import Loader from '../../../common/Loader';

export function Checking() {
  return (
    <Row>
      <Col md={{ size: 8, offset: 2 }}>
        <Card className="border-light">
          <CardHeader>
            <CardTitle className="text-center">
              <h1>検索しています</h1>
            </CardTitle>
          </CardHeader>
          <CardBody>
            <Loader type="water" />
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}
