// @flow
import JQueryView, { on } from '../../../common/JQueryView';

export default class IndexView extends JQueryView {
  constructor() {
    super('#index_view');
  }

  @on('click .open')
  onClickOpen(event: any) {
    $(event.currentTarget)
      .closest('.information-detail')
      .find('.badge-new-arrival')
      .remove();
    $(event.currentTarget)
      .closest('.information-detail')
      .find('.title-bold')
      .removeClass('title-bold');
  }
}
