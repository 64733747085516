// @flow
import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import * as linkHeaderParser from 'linkheader-parser/dist/linkheader-parser-node';
import Loader from '../common/Loader';
import type { Company } from '../../models/companies/Company';
import type { Category } from '../../models/categories/Category';
import type { Area } from '../../models/companies/areas/Area';
import type { Feature } from '../../models/companies/features/Feature';
import type { EcfStage } from '../../models/companies/ecf_stages/EcfStage';
import { CompanyClient } from '../../models/companies/CompanyClient';
import { ConditionList } from './ConditionList';
import { CompanyCard } from './CompanyCard';
import {
  buildRansackQuery,
  fundingBefores,
} from '../../models/companies/Company';
import { fundingAfters } from '../../models/companies/Company';
import { exitExperiences } from '../../models/companies/Company';
import { fundingSchemes } from '../../models/companies/Company';
import { angelTaxes } from '../../models/companies/Company';
import { sdgs } from '../../models/companies/Company';
import { ecfFundings } from '../../models/companies/Company';

type Props = {
  categories: Array<Category>,
  areas: Array<Area>,
  features: Array<Feature>,
  ecfStages: Array<EcfStage>,
};

type State = {
  companies: Array<Company>,
  keyword: string,
  isLoading: boolean,
  page: number,
  hasNextPage: boolean,
  typingTimeout: any,
  fundingBefore: ?number,
  isOpenFundingBefores: boolean,
  fundingAfter: ?number,
  isOpenFundingAfters: boolean,
  exitExperience: ?number,
  isOpenExitExperiences: boolean,
  category: ?number,
  isOpenCategories: boolean,
  area: ?number,
  isOpenAreas: boolean,
  fundingScheme: ?number,
  isOpenFundingSchemes: boolean,
  angelTax: ?number,
  isOpenAngelTaxes: boolean,
  sdg: ?number,
  isOpenSdgs: boolean,
  feature: ?number,
  isOpenFeatures: boolean,
  ecfStage: ?number,
  isOpenEcfStages: boolean,
  ecfFunding: ?number,
  isOpenEcfFundings: boolean,
};

export default class IndexView extends React.Component<Props, State> {
  companyClient = new CompanyClient();

  constructor(props: Props) {
    super(props);

    this.state = {
      companies: [],
      keyword: '',
      isLoading: true,
      page: 1,
      hasNextPage: true,
      typingTimeout: 0,
      fundingBefore: null,
      isOpenFundingBefores: false,
      fundingAfter: null,
      isOpenFundingAfters: false,
      exitExperience: null,
      isOpenExitExperiences: false,
      category: null,
      isOpenCategories: false,
      area: null,
      isOpenAreas: false,
      fundingScheme: null,
      isOpenFundingSchemes: false,
      angelTax: null,
      isOpenAngelTaxes: false,
      sdg: null,
      isOpenSdgs: false,
      feature: null,
      isOpenFeatures: false,
      ecfStage: null,
      isOpenEcfStages: false,
      ecfFunding: null,
      isOpenEcfFundings: false,
    };
  }

  componentDidMount() {
    this.fetchCompanies();
  }

  fetchCompanies() {
    const {
      page,
      keyword,
      fundingBefore,
      fundingAfter,
      exitExperience,
      category,
      area,
      fundingScheme,
      angelTax,
      sdg,
      feature,
      ecfStage,
      ecfFunding,
    } = this.state;
    const query = buildRansackQuery(
      keyword,
      fundingBefore,
      fundingAfter,
      exitExperience,
      category,
      area,
      fundingScheme,
      angelTax,
      sdg,
      feature,
      ecfStage,
      ecfFunding,
    );

    this.companyClient
      .searchCompanies(page, query)
      .then(response => {
        const hasNextPage =
          response.headers &&
          response.headers.hasOwnProperty('link') &&
          linkHeaderParser.parse(response.headers.link).hasOwnProperty('next');
        this.setState({
          companies: [...this.state.companies, ...response.data],
          hasNextPage: hasNextPage,
          isLoading: false,
        });
      })
      .catch(error => {
        console.warn(error);
      });
  }

  onChangeKeyword(keyword: string) {
    const { typingTimeout } = this.state;

    if (typingTimeout > 0) {
      clearTimeout(typingTimeout);
    }

    this.setState({
      companies: [],
      page: 1,
      isLoading: true,
      hasNextPage: true,
      keyword: keyword,
      typingTimeout: setTimeout(() => {
        this.fetchCompanies();
      }, 1000),
    });
  }

  onClickCondition(name: string, id: ?number) {
    if (!this.state.isLoading) {
      this.setState(
        {
          companies: [],
          page: 1,
          isLoading: true,
          hasNextPage: true,
          keyword: '',
          [name]: id,
        },
        () => this.fetchCompanies(),
      );
    }
  }

  onLoadMore() {
    const { isLoading, hasNextPage, page } = this.state;

    if (!isLoading && hasNextPage) {
      this.setState(
        {
          page: page + 1,
          isLoading: true,
        },
        () => this.fetchCompanies(),
      );
    }
  }

  render() {
    const { categories, areas, features, ecfStages } = this.props;
    const {
      companies,
      keyword,
      isLoading,
      fundingBefore,
      isOpenFundingBefores,
      fundingAfter,
      isOpenFundingAfters,
      exitExperience,
      isOpenExitExperiences,
      category,
      isOpenCategories,
      area,
      isOpenAreas,
      fundingScheme,
      isOpenFundingSchemes,
      angelTax,
      isOpenAngelTaxes,
      sdg,
      isOpenSdgs,
      feature,
      isOpenFeatures,
      ecfStage,
      isOpenEcfStages,
      ecfFunding,
      isOpenEcfFundings,
    } = this.state;

    return (
      <>
        <div className="row mb-20">
          <div className="col-md-4 mb-20">
            <h1>調達企業一覧</h1>
          </div>
          <div className="col-md-8 mb-20">
            <div className="search-wrapper">
              <form>
                <div className="input-group">
                  <div className="input-group-addon">
                    <i className="ion-ios-search" />
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    name="keyword"
                    maxLength="50"
                    value={keyword}
                    onChange={e => this.onChangeKeyword(e.target.value)}
                    placeholder="検索"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 side-menu mb-40">
            <ConditionList
              name="FUNDINNO利用前調達"
              items={fundingBefores}
              activeItemId={fundingBefore}
              isOpen={isOpenFundingBefores}
              className="funding-before"
              onClick={() =>
                this.setState({ isOpenFundingBefores: !isOpenFundingBefores })
              }
              onClickItem={id => this.onClickCondition('fundingBefore', id)}
            />
            <ConditionList
              name="FUNDINNO利用後調達"
              items={fundingAfters}
              activeItemId={fundingAfter}
              isOpen={isOpenFundingAfters}
              className="funding-after"
              onClick={() =>
                this.setState({ isOpenFundingAfters: !isOpenFundingAfters })
              }
              onClickItem={id => this.onClickCondition('fundingAfter', id)}
            />
            <ConditionList
              name="EXIT実績"
              items={exitExperiences}
              activeItemId={exitExperience}
              isOpen={isOpenExitExperiences}
              className="exit-experience"
              onClick={() =>
                this.setState({ isOpenExitExperiences: !isOpenExitExperiences })
              }
              onClickItem={id => this.onClickCondition('exitExperience', id)}
            />
            <ConditionList
              name="SDGsへの取り組み"
              items={sdgs}
              activeItemId={sdg}
              isOpen={isOpenSdgs}
              className="sdgs"
              onClick={() => this.setState({ isOpenSdgs: !isOpenSdgs })}
              onClickItem={id => this.onClickCondition('sdg', id)}
            />
            <ConditionList
              name="業種"
              items={categories}
              activeItemId={category}
              isOpen={isOpenCategories}
              className="categories"
              onClick={() =>
                this.setState({ isOpenCategories: !isOpenCategories })
              }
              onClickItem={id => this.onClickCondition('category', id)}
            />
            <ConditionList
              name="ECF複数回調達実績"
              items={ecfFundings}
              activeItemId={ecfFunding}
              isOpen={isOpenEcfFundings}
              className="ecf-funding"
              onClick={() =>
                this.setState({ isOpenEcfFundings: !isOpenEcfFundings })
              }
              onClickItem={id => this.onClickCondition('ecfFunding', id)}
            />
            <ConditionList
              name="地域"
              items={areas}
              activeItemId={area}
              isOpen={isOpenAreas}
              className="areas"
              onClick={() => this.setState({ isOpenAreas: !isOpenAreas })}
              onClickItem={id => this.onClickCondition('area', id)}
            />
            <ConditionList
              name="調達スキーム"
              items={fundingSchemes}
              activeItemId={fundingScheme}
              isOpen={isOpenFundingSchemes}
              className="funding-schemes"
              onClick={() =>
                this.setState({ isOpenFundingSchemes: !isOpenFundingSchemes })
              }
              onClickItem={id => this.onClickCondition('fundingScheme', id)}
            />
            <ConditionList
              name="エンジェル税制"
              items={angelTaxes}
              activeItemId={angelTax}
              isOpen={isOpenAngelTaxes}
              className="angel-tax"
              onClick={() =>
                this.setState({ isOpenAngelTaxes: !isOpenAngelTaxes })
              }
              onClickItem={id => this.onClickCondition('angelTax', id)}
            />
            <ConditionList
              name="ECF利用時のステージ"
              items={ecfStages}
              activeItemId={ecfStage}
              isOpen={isOpenEcfStages}
              className="ecf-stages"
              onClick={() =>
                this.setState({ isOpenEcfStages: !isOpenEcfStages })
              }
              onClickItem={id => this.onClickCondition('ecfStage', id)}
            />
            <ConditionList
              name="企業の特徴"
              items={features}
              activeItemId={feature}
              isOpen={isOpenFeatures}
              className="features"
              onClick={() => this.setState({ isOpenFeatures: !isOpenFeatures })}
              onClickItem={id => this.onClickCondition('feature', id)}
            />
          </div>
          <div className="col-md-8 company-cards">
            <InfiniteScroll
              pageStart={0}
              loadMore={() => this.onLoadMore()}
              hasMore={this.state.hasNextPage}
              loader={<Loader key={0} type="water" />}
              className="row"
            >
              {companies.map(company => (
                <a
                  href={`/companies/${company.id}`}
                  key={company.id}
                  className="col-md-6"
                >
                  <CompanyCard company={company} />
                </a>
              ))}
            </InfiniteScroll>
            {!isLoading && companies.length === 0 && (
              <div className="not-found text-center">
                企業は見つかりませんでした
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}
