// @flow
import JQueryView, { on } from '../common/JQueryView';

declare var $: any;

export default class ShowView extends JQueryView {
  shareholderCommunityId: number;
  hasIncompletedProjects: boolean;

  constructor(shareholderCommunityId: number, hasIncompletedProjects: boolean) {
    super('.show');
    this.shareholderCommunityId = shareholderCommunityId;
    this.hasIncompletedProjects = hasIncompletedProjects;
    this.render();
  }

  @on('click .toggle-content')
  onClick(event: any) {
    const id = event.currentTarget.dataset.contentId;
    $(`#${id}`).slideToggle();
    $(event.currentTarget)
      .find('i')
      .toggleClass('fa-chevron-down');
    $(event.currentTarget)
      .find('i')
      .toggleClass('fa-chevron-up');
  }

  render() {
    const sessionKey = 'accessed-community' + this.shareholderCommunityId;
    const sessionValue = 'true';

    if (!sessionStorage.getItem(sessionKey) && this.hasIncompletedProjects) {
      $('#modal_project_notification').modal('show');
      sessionStorage.setItem(sessionKey, sessionValue);
    }
  }
}
