// @flow
import JQueryView from '../../common/JQueryView';

declare var $: any;

export default class EkycView extends JQueryView {
  constructor(phoneOrTablet: boolean, ekycRunning: boolean) {
    super('#ekyc_view');

    if (phoneOrTablet && ekycRunning) {
      $(window).scroll(() => {
        const footerTop = $('#ekyc_button_place').offset().top;
        const scrollPosition = $(window).height() + $(window).scrollTop();
        const footHeight = $('#ekyc_button').innerHeight();

        if (scrollPosition - footerTop >= footHeight) {
          $('#ekyc_button').css({
            position: 'static',
            bottom: 'auto',
          });
        } else {
          $('#ekyc_button').css({
            position: 'fixed',
            bottom: '0px',
          });
        }
      });
    }
  }
}
