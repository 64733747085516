// @flow
import JQueryView, { on } from '../../../common/JQueryView';

declare var $: any;

export default class FormView extends JQueryView {
  last_name: string;
  first_name: string;
  last_name_kana: string;
  first_name_kana: string;
  position_description: string;
  gender: string;

  constructor(
    last_name: string,
    first_name: string,
    last_name_kana: string,
    first_name_kana: string,
    position_description: string,
    gender: string,
  ) {
    super('#form_view');

    this.last_name = last_name;
    this.first_name = first_name;
    this.last_name_kana = last_name_kana;
    this.first_name_kana = first_name_kana;
    this.position_description = position_description;
    this.gender = gender;

    $(document).ready(() => {
      $('#investor_corporate_transaction_person_zip_code').jpostal({
        postcode: ['#investor_corporate_transaction_person_zip_code'],
        address: {
          '#investor_corporate_transaction_person_prefecture_id': '%3',
          '#investor_corporate_transaction_person_city': '%4',
          '#investor_corporate_transaction_person_address1': '%5',
        },
      });

      $('#investor_corporate_transaction_person_birth_on_1i').after(
        '<span class="ml-5 mr-10">年</span>',
      );
      $('#investor_corporate_transaction_person_birth_on_2i').after(
        '<span class="ml-5 mr-10">月</span>',
      );
      $('#investor_corporate_transaction_person_birth_on_3i').after(
        '<span class="ml-5 mr-10">日</span>',
      );

      this.render();
    });
  }

  @on(
    'change input[name="investor_corporate_transaction_person[position]"]:radio',
  )
  onChengePosition() {
    this.render();
  }

  @on('change #investor_corporate_transaction_person_zip_code')
  onChangeUserZipCode() {
    this.render();
  }

  @on('blur #investor_corporate_transaction_person_zip_code')
  onBlurZipCode() {
    const original_zip_code = $(
      '#investor_corporate_transaction_person_zip_code',
    ).val();
    if (original_zip_code.length === 0) {
      return;
    }

    let formatted_zip_code = original_zip_code
      .replace('ー', '-')
      .replace('－', '-')
      .replace('−', '-')
      .replace('ｰ', '-')
      .replace(/[０-９]/g, s => String.fromCharCode(s.charCodeAt(0) - 0xfee0));

    if (/^\d*$/.test(formatted_zip_code) === true) {
      if (formatted_zip_code.length >= 4) {
        formatted_zip_code =
          formatted_zip_code.substr(0, 3) + '-' + formatted_zip_code.substr(3);
      }
    }

    // change()を呼び出すことで最終的に住所の自動入力が走ります
    if (formatted_zip_code !== original_zip_code) {
      $('#investor_corporate_transaction_person_zip_code')
        .val(formatted_zip_code)
        .change();
    }
  }

  render() {
    const position = $(
      'input[name="investor_corporate_transaction_person[position]"]',
    )
      .filter(':checked')
      .val();

    if (position === 'representative') {
      $('#investor_corporate_transaction_person_last_name').prop(
        'disabled',
        true,
      );
      $('#investor_corporate_transaction_person_last_name').val(this.last_name);
      $('#investor_corporate_transaction_person_first_name').prop(
        'disabled',
        true,
      );
      $('#investor_corporate_transaction_person_first_name').val(
        this.first_name,
      );
      $('#investor_corporate_transaction_person_last_name_kana').prop(
        'disabled',
        true,
      );
      $('#investor_corporate_transaction_person_last_name_kana').val(
        this.last_name_kana,
      );
      $('#investor_corporate_transaction_person_first_name_kana').prop(
        'disabled',
        true,
      );
      $('#investor_corporate_transaction_person_first_name_kana').val(
        this.first_name_kana,
      );
      if (this.gender == 'male') {
        $('#investor_corporate_transaction_person_gender_female').prop(
          'disabled',
          true,
        );
        $('#investor_corporate_transaction_person_gender_male').prop(
          'checked',
          true,
        );
      } else {
        $('#investor_corporate_transaction_person_gender_male').prop(
          'disabled',
          true,
        );
        $('#investor_corporate_transaction_person_gender_female').prop(
          'checked',
          true,
        );
      }
      $('#investor_corporate_transaction_person_position_description').prop(
        'disabled',
        true,
      );
      $('#investor_corporate_transaction_person_position_description').val(
        this.position_description,
      );
    } else {
      $('#investor_corporate_transaction_person_last_name').prop(
        'disabled',
        false,
      );
      $('#investor_corporate_transaction_person_first_name').prop(
        'disabled',
        false,
      );
      $('#investor_corporate_transaction_person_last_name_kana').prop(
        'disabled',
        false,
      );
      $('#investor_corporate_transaction_person_first_name_kana').prop(
        'disabled',
        false,
      );
      $('#investor_corporate_transaction_person_gender_male').prop(
        'disabled',
        false,
      );
      $('#investor_corporate_transaction_person_gender_female').prop(
        'disabled',
        false,
      );
      $('#investor_corporate_transaction_person_position_description').prop(
        'disabled',
        false,
      );
    }
  }
}
