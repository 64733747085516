// @flow
import JQueryView, { on } from '../../../common/JQueryView';

export default class FormView extends JQueryView {
  constructor() {
    super('#pep_form_view');
    this.render();
  }

  @on('change input[name="investor_pep[country]"]:radio')
  onChangeCountry() {
    this.render();
  }

  @on('change input[name="investor_pep[has_residence]"]:radio')
  onChangeHasResidence() {
    this.render();
  }

  @on('change input[name="investor_pep[taxpayer_number_status]"]:radio')
  onChangeTaxpayerNumberStatus() {
    this.render();
  }

  @on('change input[name="investor_pep[peps]"]:radio')
  onChangePeps() {
    this.render();
  }

  @on('change input[name="investor_pep[pep_relationship]"]:radio')
  onChangeRelationship() {
    this.render();
  }

  @on('change input[name="investor_pep[fatca]"]:radio')
  onChangeFatca() {
    this.render();
  }

  render() {
    const country = parseInt(
      $('input[name="investor_pep[country]"]')
        .filter(':checked')
        .val(),
    );
    const peps = $('input[name="investor_pep[peps]"]')
      .filter(':checked')
      .val();
    const fatca = $('input[name="investor_pep[fatca]"]')
      .filter(':checked')
      .val();

    if (country != null && country === 1) {
      const hasResidence = $('input[name="investor_pep[has_residence]"]')
        .filter(':checked')
        .val();
      $('#residence_country').show();

      if (hasResidence != null && hasResidence === 'true') {
        $('#residence_exists').show();

        if (
          parseInt(
            $('input[name="investor_pep[taxpayer_number_status]"]')
              .filter(':checked')
              .val(),
          ) == 0
        ) {
          $('.investor_pep_taxpayer_number').show();
        } else {
          $('.investor_pep_taxpayer_number').hide();
        }
      } else {
        $('#residence_exists').hide();
      }
    } else {
      $('#residence_country').hide();
    }

    if (peps != null && peps === 'true') {
      const pepRelationShip = parseInt(
        $('input[name="investor_pep[pep_relationship]"]')
          .filter(':checked')
          .val(),
      );
      $('#peps').show();

      if (pepRelationShip === 1) {
        $('.investor_pep_pep_relationship_note').show();
        $('.investor_pep_pep_relationship_name').show();
      } else {
        $('.investor_pep_pep_relationship_note').hide();
        $('.investor_pep_pep_relationship_name').hide();
      }
    } else {
      $('#peps').hide();
    }

    if (fatca != null && fatca === 'true') {
      $('#fatca').show();
    } else {
      $('#fatca').hide();
    }
  }
}
