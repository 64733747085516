// @flow
import JQueryView, { on } from '../../../common/JQueryView';

export default class OnlineMeetingView extends JQueryView {
  constructor() {
    super('#online_meeting_area');
    this.renderOnlineMeetingArea();
  }

  @on('change input[name="company_ir[use_online_meeting]"]:radio')
  onChangeUseOnlineMeeting() {
    this.renderOnlineMeetingArea();
  }

  renderOnlineMeetingArea() {
    const useOnlineMeeting = $('input[name="company_ir[use_online_meeting]"]')
      .filter(':checked')
      .val();

    if (useOnlineMeeting === 'true') {
      $('#input_online_meeting_others').show();
    } else {
      $('#input_online_meeting_others').hide();
    }
  }
}
